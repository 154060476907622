import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from 'i18next';


import { Link, useNavigate, useParams } from 'react-router-dom';
import CurrentAccountService from '../../services/CurrentAccountService';
import CurrentAccountTransactionService from '../../services/CurrentAccountTransactionService';
import UserService from '../../services/UserService';
import useAuth from '../../hooks/useAuth';
import { authService } from '../../services/auth/AuthService';
//import FormData from 'form-data';
import { ControlPointDuplicateOutlined } from '@material-ui/icons';

import BranchAccountService from '../../services/BranchAccountService'

import Moment, { format } from 'moment';

import HelperUtil from '../../helpers/HelperUtil';

const AddNewMove = ({ addMove, setAddMove, userId, setUserId, accountId, setAccountId }) => {

  const [currentAccountId, setCurrentAccountId] = useState('')
  const [createdByUserId, setCreatedByUserId] = useState('')
  const [createdByUserName, setCreatedByUserName] = useState('')
  const [createdByLoginName, setCreatedByLoginName] = useState('')
  const [accountCurrency, setAccountCurrency] = useState('')
  const [transactionStatus, setTransactionStatus] = useState('')
  const [statusChangeDate, setStatusChangeDate] = useState('')
  const [allowedStatuses, setAllowedStatuses] = useState([])
  const [reasonForCancelling, setReasonForCancelling] = useState('')
  const [reasonForReactivation, setReasonForReactivation] = useState('')
  const [transactionType, setTransactionType] = useState('')
  const [transactionAmount, setTransactionAmount] = useState('')
  const [executionDate, setExecutionDate] = useState('')
  const [currentAccounts, setCurrentAccounts] = useState([])
  const [editedByUserId, setEditedByUserId] = useState('')
  // const [currentAccount, setCurrentAccount] = useState("")
  const [transactionProof, setTransactionProof] = useState('')

  //work around till you fix this
  const [transactionAdded, setTransactionAdded] = useState('')
  const proof = new FormData();
  const [errors, setErrors] = useState('')
  const [statusError, setStatusError] = useState('')
  const [notes, setNotes] = useState('')
  const navigate = useNavigate();
  const { auth } = useAuth();

  const [branchAccounts, setBranchAccounts] = useState([])
  const [branchAccountId, setBranchAccountId] = useState('')
  // const { accountId } = useParams();
  let id = userId

  const handleClose = () => {
    setAddMove(false)
    setUserId(null)
    id = null
    setCurrentAccountId("")
    setCreatedByUserId("")
    setCreatedByUserName("")
    setCreatedByLoginName("")
    setNotes("")
    setTransactionStatus("")
    setStatusChangeDate("")
    initialiseAllowedStatuses("")
    setReasonForReactivation("")
    setReasonForCancelling("")
    setTransactionAmount("")
    setTransactionType("")
    setExecutionDate("")
    setAccountCurrency("")
    fetchCurrentAccounts("")
    setAccountId(null)
    setBranchAccountId("")
  };


  const initialiseAllowedStatuses = (trStatus) => {
    if (trStatus == 'ACTIVE') {
      allowedStatuses.push('ACTIVE')
      allowedStatuses.push('CANCELLED')
      // setAllowedStatuses(['ACTIVE', 'CANCELLED']);
    }
    else if (trStatus == 'CANCELLED') {
      allowedStatuses.push('CANCELLED')
      allowedStatuses.push('REACTIVATED')
      //setAllowedStatuses(['CANCELLED', 'REACTIVATED']);
    }
    else if (trStatus == 'REACTIVATED') {
      allowedStatuses.push('REACTIVATED')
      allowedStatuses.push('CANCELLED')
      // setAllowedStatuses(['REACTIVATED', 'CANCELLED'])
    }
    // alert(allowedStatuses);
  }

  const generateSubmitButtonText = () => {
    //  
    if (transactionStatus === 'ACTIVE' || transactionStatus == "" || !transactionStatus) {
      return t('crud_general_save')
    }
    else if (transactionStatus === 'CANCELLED') {
      return t('crud_transaction_cancel')
    }
    else if (transactionStatus === 'REACTIVATED') {
      return t('crud_transaction_reactivate')
    }
  }

  const generateStatusDropDownListText = (trStatus) => {
    if (trStatus === 'ACTIVE') {
      return t('current_account_transactio_status_active')
    }
    else if (trStatus === 'CANCELLED') {
      return t('current_account_transactio_status_cancelled')

    }
    else if (trStatus === 'REACTIVATED') {
      return t('current_account_transactio_status_reactivated')
    }
  }

  const saveOrUpdateCurrentAccountTransaction = (e) => {
    e.preventDefault();
    //something missing
    if (currentAccountId === undefined || currentAccountId === "" || transactionType === undefined || transactionType === ""
      || transactionAmount === undefined || transactionAmount === "") {
      setErrors("errors");
    }
    else if (
      (transactionStatus === 'REACTIVATED' && (reasonForReactivation == "" || !reasonForReactivation))
      || (transactionStatus === 'CANCELLED' && (reasonForCancelling == "" || !reasonForCancelling))) {
      setStatusError("errors");
    }

    else {
  //    setCreatedByLoginName("");
     
      console.log("Add move" + authService.currentUserValue.id)
         //   createdByLoginName = authService.currentUserValue.username;
      const currentAccountTransaction = {
        currentAccountId, createdByUserId, createdByUserName, createdByLoginName, editedByUserId,
        transactionType, transactionAmount, transactionStatus, reasonForCancelling, reasonForReactivation, executionDate, notes, branchAccountId
      }

      if (id) {
        console.log("Update with id", id)
        // console.log(transactionProof);
        CurrentAccountTransactionService.updateCurrentAccountTransaction(id, currentAccountTransaction)
          .then((response) => {
            // setUserId(null)
            handleClose()
            // navigate('/currentAccountTransactions/' + currentAccountId);
          }).catch(error => {
            console.log(error)
          })
      } else {
        CurrentAccountTransactionService.createCurrentAccountTransaction(currentAccountTransaction)
          .then((response) => {
            // setUserId(null)
            handleClose()
            // navigate('/currentAccountTransactions/' + currentAccountId);
          }).catch(error => {
            console.log(error)
          })
      }
    } //else everything is entered
  }

  useEffect(() => {
    setEditedByUserId(authService.currentUserValue.id);
    fetchUserAccessibleBranchAccounts();

    setExecutionDate(new Date());
    if (accountId) {
      CurrentAccountService.getCurrentAccountById(accountId).then((response) => {
        setCurrentAccountId(response.data.id)
        setAccountCurrency(response.data.accountCurrency)
        fetchCurrentAccounts(response.data.accountCurrency)
      }).catch(error => {
        console.log(error)
      })
    }
    else if (id) {
      CurrentAccountTransactionService.getCurrentAccountTransactionById(id).then((response) => {
        setCurrentAccountId(response.data.currentAccountId)
        setCreatedByUserId(response.data.createdByUserId)
        setCreatedByUserName(response.data.createdByUserName)
        setCreatedByLoginName(response.data.createdByLoginName)
        setNotes(response.data.notes)
        setTransactionStatus(response.data.transactionStatus)
        setStatusChangeDate(response.data.statusChangeDate)
        initialiseAllowedStatuses(response.data.transactionStatus)
        setReasonForReactivation(response.data.reasonForReactivation)
        setReasonForCancelling(response.data.reasonForCancelling)
        setTransactionAmount(response.data.transactionAmount)
        setTransactionType(response.data.transactionType)
        setExecutionDate(response.data.executionDate)
        setAccountCurrency(response.data.accountCurrency)
        fetchCurrentAccounts(response.data.accountCurrency)
        setBranchAccountId(response.data?.branchAccountId)
      }).catch(error => {
        console.log(error)
      })
    }

    if (id === null || id === undefined) {
      setCreatedByLoginName(authService.currentUserValue.username);
    }

  }, [accountId, id, addMove])

  const fetchCurrentAccounts = (currency) => {
    // console.log("Hey Ahmed");
    // console.log(currency); //getCurrentAccountsOfSpecificCurrency
    setAccountCurrency(currency);
    CurrentAccountService.getCurrentAccountsOfSpecificCurrency(currency).then((response) => {
      setCurrentAccounts(response.data)
    })
  }


  const fetchUserAccessibleBranchAccounts = () =>{
    BranchAccountService.getAccessibleBranchAccounts(editedByUserId).then((response) => {
      setBranchAccounts(response.data)
    }
    
    )

  }

  const title = () => {
    if (id) {
      return <h2 className="text-center">{t('current_account_transaction_update')}</h2>
    } else {
      return <h2 className="text-center">{t('current_account_transaction_add_new')}</h2>
    }
  }


  return (
    <>
      <Modal show={addMove} onHide={handleClose} size="lg" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="card-body py-0">
            <form className="p-0">
              {
                errors !== "" ? (
                  <div id="errorMsg" className="alert alert-danger" role="alert">
                    {t('current_account')} , {t('current_account_transaction_type')} , {t('current_account_transaction_amount')} {t('validation_general_empty_error')}

                  </div>
                ) : (
                  statusError !== "" ? (
                    <div id="errorMsg" className="alert alert-danger" role="alert">
                      {t('current_account_transactio_status_change_msg')}
                    </div>
                  ) :
                    (
                      <div> </div>
                    )
                )
              }

              <div className="d-flex justify-content-between align-items-center">
                <div className="form-group mb-2 w-half-modal">
                  <label className="form-label">{t('current_account')}</label>
                  <select
                    name="currentAccount"
                    // placeholder="Choose Current Account"
                    placeholder={`${currentAccountId}`}
                    className="form-control"
                    value={currentAccountId}
                    // onChange={(e) => setCurrentAccountId(e.target.value)}
                    disabled
                    >
                    <option> {t('guide_placeholder_choose_text')} {t('current_account')}</option>
                    {
                      currentAccounts.map(
                        currentAccount =>
                          <option key={currentAccount.id} value={currentAccount.id}>
                            {HelperUtil.getCurrencyNameLocale(currentAccount.accountCurrency)} ::: {currentAccount.userName}
                          </option>)
                    }
                  </select>
                </div>

                {transactionStatus != "" && transactionStatus ? (
                  <div className="form-group mb-2 w-half-modal">
                    <label className="form-label">  {t('current_account_transactio_status')}</label>
                    <select
                      name="transactionStatus"
                      placeholder="Choose Status"
                      className="form-control"
                      value={transactionStatus}
                      onChange={(e) => setTransactionStatus(e.target.value)}>

                      {
                        allowedStatuses.map(
                          tranStatus =>
                            <option key={tranStatus} value={tranStatus}>
                              {generateStatusDropDownListText(tranStatus)}
                            </option>)
                      }
                    </select>
                  </div>
                ) :
                  (
                    <div className="form-group mb-2 w-half-modal">
                      <label className="form-label">  {t('current_account_transactio_status')}</label>
                      <select
                        name="transactionStatus"
                        placeholder="Choose Status"
                        className="form-control"
                        value={transactionStatus}
                        onChange={(e) => setTransactionStatus(e.target.value)}>
                        <option key={'ACTIVE'} value={'ACTIVE'}>
                          {generateStatusDropDownListText('ACTIVE')}
                        </option>

                      </select>
                    </div>
                  )
                }
              </div>

              <div className="form-group mb-2">
                <label className="form-label"> {t('current_account_transaction_type')}</label>
                <select
                  name="currency"
                  placeholder="Choose Transaction Type"
                  className="form-control"
                  value={transactionType}
                  onChange={(e) => setTransactionType(e.target.value)}>
                  <option> {t('guide_placeholder_choose_text')} {t('current_account_transaction_type')}</option>
                  <option value="DEPOSIT">{t('deposit')}</option>
                  <option value="WITHDRAW">{t('withdraw')}</option>
                </select>
              </div>

              <div className="form-group mb-2">
                <label className="form-label"> {t('current_account_transaction_amount')}</label>
                <input
                  type="text"
                  placeholder={t('guide_placeholder_enter_text') + ' ' + t('current_account_transaction_amount')}
                  name="transactionAmount"
                  className="form-control"
                  value={transactionAmount}
                  onChange={(e) => setTransactionAmount(e.target.value)}
                >
                </input>
              </div>

              {transactionStatus === 'CANCELLED' ? (
                <div className="form-group mb-2">
                  <label className="form-label"> {t('current_account_transaction_cancellation_reason')} </label>
                  <input
                    type="text"
                    placeholder={t('current_account_transaction_cancellation_reason')}
                    name="cancelReason"
                    className="form-control"
                    value={reasonForCancelling ? reasonForCancelling : ""}
                    onChange={(e) => setReasonForCancelling(e.target.value)}
                  >
                  </input>
                </div>
              ) :
                (transactionStatus === 'REACTIVATED' ?
                  (
                    <div className="form-group mb-2">
                      <label className="form-label"> {t('current_account_transaction_reactication_reason')} </label>
                      <input
                        type="text"
                        placeholder={t('current_account_transaction_reactication_reason')}
                        name="reactivationReason"
                        className="form-control"
                        value={reasonForReactivation ? reasonForReactivation : ""}
                        onChange={(e) => setReasonForReactivation(e.target.value)}
                      >
                      </input>
                    </div>
                  ) :
                  (
                    <div className="form-group mb-2">
                      <label className="form-label">{t('current_account_transaction_notes')}</label>
                      <input
                        type="text"
                        placeholder="Enter Transaction Notes"
                        name="balance"
                        className="form-control"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      >
                      </input>
                    </div>
                  )
                )
              }

              {/* <div className="form-group mb-2">
                <label className="form-label"> {t('currency')} </label>
                <select
                  name="currency"
                  placeholder="Enter Currency"
                  className="form-control"
                  value={accountCurrency}

                  onChange={(e) => fetchCurrentAccounts(e.target.value)}>
                  <option>{t('guide_placeholder_choose_text')} {t('currency')}</option>
                  <option value="USD">{t('currency_usd')}</option>
                  <option value="EUR">{t('currency_eur')}</option>
                  <option value="JOD">{t('currency_jod')}</option>
                  <option value="ILS">{t('currency_ils')}</option>
                  <option value="TRY">{t('currency_try')}</option>
                  <option value="AED">{t('currency_aed')}</option>
                  <option value="EGP">{t('currency_egp')}</option>
                </select>
              </div> */}

              <div className="d-flex justify-content-between align-items-center">
                <div className="form-group mb-2 w-half-modal">
                  <label className="form-label"> {t('current_account_transaction_created_by')} </label>
                  <input
                    type="text"
                    placeholder="Created By"
                    name="clientId"
                    className="form-control"
                    value={createdByLoginName}
                    disabled
                    onChange={(e) => setCreatedByLoginName(e.target.value)}
                  >
                  </input>
                </div>

                <div className="form-group mb-2 w-half-modal">
                  <label className="form-label">{t('current_account_transaction_Execution_Date')}</label>
                  <input
                    type="text"
                    placeholder="Enter Execution Date"
                    name="balance"
                    className="form-control"
                    disabled
                    value={Moment(executionDate).format('MMMM Do YYYY, h:mm:ss a')}
                  >
                  </input>
                </div>
              </div>

              <div className="form-group mb-2 w-half-modal">
                    <label className="form-label">{t('branch_account')} </label>
                    <select
                      name="branchAccount"
                      placeholder="Choose Status"
                      className="form-control"
                      value={branchAccountId}
                      onChange={(e) => setBranchAccountId(e.target.value)}>

                    <option> {t('guide_placeholder_choose_text')} {t('branch_account')}</option>
                      {
                     
                        branchAccounts.map(
                          branchAccount =>
                            <option key={branchAccount.id} value={branchAccount.id}>
                              {branchAccount.branchAccountName}
                            </option>)
                      }
                    </select>
                  </div>
              <div className="form-group mb-2">
                <label className="form-label">{t('current_account_transaction_proof')} </label>
                <input
                  type="file"
                  placeholder={t('guide_placeholder_choose_file')}
                  name="proof"
                  className="form-control"
                  onChange={(e) => setTransactionProof(e.target.files[0])}
                >
                </input>
              </div>
            </form>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateCurrentAccountTransaction(e)} >
            {/* {t('crud_general_save')} */}
            {generateSubmitButtonText()}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddNewMove