import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { CheckIsAuth } from '../components/dashboard/CheackIsAuth'
import { apiClient } from "../services/auth/ApiClient"
import { authService } from '../services/auth/AuthService'
import { useTranslation } from 'react-i18next'
import { notify } from '../components/Toast'
const LoginPage = () => {
  const { t } = useTranslation();

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const navigate = useNavigate();
  const location = useLocation()

  const handleSubmit = (e) => {
    e.preventDefault()

    apiClient.signIn({ username, password })
      .then(res => {
        if (res.access_token) {
          //login success
          console.log(res.user, res.access_token, res.refresh_token);
          let newUser = res.user;
          // delete newUser.authorities
          authService.login(newUser, res.access_token, res.refresh_token);
          notify({ info: "User Login successfully", status: "success" });

          navigate("/"); //redirect to home page after success login
        } else {
          //login failure
          console.log("🚀 LoginPage.js:41:~ username, password", username, password)
          notify({ info: res.message, status: "failed" });
          authService.logout();
        }
      });
  }

  const [loggedInUser, setLoggedInUser] = useState(authService.currentUserValue);
  const isAuthenticated = !!loggedInUser;

  console.log(authService.currentUserValue)

  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x));

    if(location.pathname == "/login" && isAuthenticated) {
      navigate("/")
    }
  }, []);



  return (
    <div className='py-5'>
      <div className='container'>
        <form onSubmit={handleSubmit} className="login-form">

          <h4 className='pb-4 text-lg-center'>Login</h4>

          <div className="input-group flex-nowrap mb-4">
            <input type="text" className="form-control" placeholder={t("login_page_enter_name")} required onChange={(e) => setUsername(e.target.value)} />
          </div>

          <div className="input-group flex-nowrap mb-4">
            <input type="password" className="form-control" placeholder={t("login_page_enter_password")} required onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className="mb-3 form-check">
            <Link to="/reset-password">{t("login_page_forget_password")}</Link>
          </div>

          <button type="submit" className="btn btn-primary btn-block mb-4 w-100">{t("login_page_loginfromhere")}</button>

        </form>
      </div>

    </div>
  )
}

export default LoginPage