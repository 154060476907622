import React, { useEffect, useState } from 'react';
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ListEmployeeComponent from './components/ListEmployeeComponent';
import AddEmployeeComponent from './components/AddEmployeeComponent';

import ListUserComponent from './components/ListUserComponent';
import AddUserComponent from './components/AddUserComponent';

import ListCurrentAccountComponent from './components/ListCurrentAccountComponent';
import AddCurrentAccountComponent from './components/AddCurrentAccountComponent';

import ListCurrentAccountTransactionComponent from "./components/ListCurrentAccountTransactionComponent";
import AddCurrentAccountTransactionComponent from "./components/AddCurrentAccountTransactionComponent";

import ListTransferTransactionComponent from './components/ListTransferTransactionComponent';
import AddTransferTransactionComponent from './components/AddTransferTransactionComponent';

import ListExchangeRateComponent from "./components/ListExchangeRateComponent";
import AddExchangeRateComponent from "./components/AddExchangeRateComponent";

import ListBranchAccountComponent from './components/ListBranchAccountComponent';

import ListTransferTransactionFeeComponent from "./components/ListTransferTransactionFeeComponent";
import AddTransferTransactionFeeComponent from "./components/AddTransferTransactionFeeComponent";

import ListUserTransferTransactionFeeComponent from './components/ListUserTransferTransactionFeeComponent';
import AddUserTransferTransactionFeeComponent from './components/AddUserTransferTransactionFeeComponent';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import ProductListing from "./containers/ProductListing";
import Header from "./containers/Header";
import ProductDetails from "./containers/ProductDetails";

import Login from "./components/Login";
import LoginPage from './pages/LoginPage';

import { ToastContainer } from 'react-toastify';
import { notify } from './components/Toast';
import 'react-toastify/dist/ReactToastify.css';
import Protected from './components/Protected';
import HomeUser from "./pages/HomeUser"
import SendMoney from './pages/SendMoney';
import Services from './pages/Services';
import ExchangeRates from './pages/ExchangeRates';
import CountryFees from './pages/CountryFees';


function App() {

  // const loggedIn = window.localStorage.getItem("LoggedIn");
  // alert("loggedIn");
  //alert(loggedIn);

  /*
  return (
    <div className="App">

      
    </div>
  );
  */

  // Online state
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
      if (!navigator.onLine)
        notify({ info: "There Is No Connection", status: "failed" });
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  return (
    <div className="App">

      <Router>
        <Navbar />
        <Routes>
          {/* HomeUser / About Us / Send Money / services / ExchangeRates / Contact Us  */}
          <Route path="/login" element={<LoginPage />} > </Route>

          {/*  Pages For Not Auth  */}
          <Route path="/homeUser" element={<HomeUser />} />
          <Route path="/aboutUs" element={<About />} />
          <Route path="/sendMoney" element={<SendMoney />} />
          <Route path="/services" element={<Services />} />
          <Route path="/exchangeRates" element={<ExchangeRates />} />
          <Route path="/countryFees" element={<CountryFees />} />
          <Route path="/contact" element={<Contact />} />

          <Route path="/" exact element={
            <Protected><Home /></Protected>
          } />

          <Route path="/users" element={<Protected><ListUserComponent /></Protected>} />
          {/* <Route path="/add-user" element={<Protected><AddUserComponent /></Protected>} />
          <Route path="/edit-user/:id" element={<Protected><AddUserComponent /></Protected>} /> */}

          <Route path="/currentAccounts" element={<Protected><ListCurrentAccountComponent /></Protected>} />
          {/* <Route path="/add-currentAccount" element={<Protected><AddCurrentAccountComponent /></Protected>} />
          <Route path="/edit-currentAccount/:id" element={<Protected><AddCurrentAccountComponent /></Protected>} /> */}

          <Route path="/currentAccountTransactions/:id" element={<Protected><ListCurrentAccountTransactionComponent /></Protected>} />
          {/* <Route path="/add-currentAccountTransaction/:accountId" element={<Protected><AddCurrentAccountTransactionComponent /></Protected>} />
          <Route path="/edit-currentAccountTransaction/:id" element={<Protected><AddCurrentAccountTransactionComponent /></Protected>} /> */}

          <Route path="/transferTransactions" element={<Protected><ListTransferTransactionComponent /></Protected>} />
          <Route path="/add-transferTransaction" element={<Protected><AddTransferTransactionComponent /></Protected>} />
          <Route path="/edit-transferTransaction/:id" element={<Protected><AddTransferTransactionComponent /></Protected>} />

          {/*  */}
          <Route path="/exchange-rates" element={<Protected><ListExchangeRateComponent /></Protected>} />
          {/* <Route path="/add-exchange-rate" element={<Protected><AddExchangeRateComponent /></Protected>} />
          <Route path="/edit-exchange-rate/:id" element={<Protected><AddExchangeRateComponent /></Protected>} /> */}

          <Route path="/branch-accounts" element={<Protected><ListBranchAccountComponent /> </Protected>} />

          <Route path="/transfer-fees" element={<Protected><ListTransferTransactionFeeComponent /></Protected>} />
          {/* <Route path="/add-transfer-fee" element={<Protected><AddTransferTransactionFeeComponent /></Protected>} />
          <Route path="/edit-transfer-fee/:id" element={<Protected><AddTransferTransactionFeeComponent /></Protected>} /> */}

          <Route path="/user-transfer-fees" element={<Protected><ListUserTransferTransactionFeeComponent /></Protected>} />
          {/* <Route path="/add-user-transfer-fee" element={<Protected><AddUserTransferTransactionFeeComponent /></Protected>} />
          <Route path="/edit-user-transfer-fee/:id" element={<Protected><AddUserTransferTransactionFeeComponent /></Protected>} /> */}

          <Route>404 Not Found!</Route>
        </Routes>
        <Footer />
      </Router>

      <ToastContainer position={"top-right"} autoClose={1500} />
    </div>
  )
}

export default App;

