import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import BranchAccountService from '../../services/BranchAccountService'
import UserService from '../../services/UserService';

const AddBranchAccount = ({ showAddBranchAccount, setShowAddBranchAccount, idForEdit, setIdForEdit, }) => {

  let idEdit = idForEdit

  const [branchAccountName, setBranchAccountName] = useState('')

  const [branchType, setBranchType] = useState("")
  const [relatedToExtraInfo, setRelatedToExtraInfo] = useState("")

  const saveOrUpdateBranchAccount = (e) => {
    e.preventDefault();

    const branchAccount = { branchAccountName, branchType, relatedToExtraInfo }

    if (idEdit) {
      BranchAccountService.updateBranchAccount(idEdit, branchAccount).then((response) => {
        console.log("🚀 ~ file: AddBranchAccount. ~ response:", response)
        setShowAddBranchAccount(false)
        // navigate('/transfer-fees')
      }).catch(error => {
        console.log(error)
      })
    } else {
      BranchAccountService.createBranchAccount(branchAccount).then((response) => {
        console.log(response.data)
        setShowAddBranchAccount(false)
        // navigate('/transfer-fees');
      }).catch(error => {
        console.log(error)
      })
    }
  }


  const handleClose = () => {
    setShowAddBranchAccount(false)
    setIdForEdit(null)
    idEdit = null
    setBranchAccountName("")
  };

  useEffect(() => {
    if (idEdit) {
      // console.log("AddBranchAccount.jsx 56 ~ idEdit:", idEdit)
      BranchAccountService.getBranchAccount(idEdit).then((response) => {
        console.log("🚀 ~ file: AddBranchAccount.jsx:62 ~ BranchAccountService.getBranchAccount ~ response.data:", response.data)
        setBranchAccountName(response.data.branchAccountName);
        setBranchType(response.data.branchType)
        setRelatedToExtraInfo(response.data.relatedToExtraInfo)
      }).catch(error => {
        console.log(error)
      })
    }
  }, [idEdit, showAddBranchAccount])

  const title = () => {
    if (idEdit) {
      return <h2 className="text-center">{t('branch_account_update')}</h2>
    } else {
      return <h2 className="text-center">{t('branch_account_add_new')}</h2>
    }
  }


  // branchAccountName: "صندوق خانيونس"
  // branchType: "GHALBAN_OFFICE"
  // id: 1
  // relatedToExtraInfo: " - "
  // relatedToId: 0

  return (
    <>
      <Modal show={showAddBranchAccount} onHide={handleClose} size="md" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="card col-md-12">
              <div className="card-body">
                <form className='p-0'>

                  <div className="form-group mb-2">
                    <label className="form-label">Branch Account Name </label>
                    <input
                      type="text"
                      placeholder={t('transfer_fee_value_is_compared_to_value')}
                      name="transferFee"
                      className="form-control"
                      value={branchAccountName}
                      onChange={(e) => setBranchAccountName(e.target.value)}
                    >
                    </input>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">Branch Type</label>
                    <input
                      type="text"
                      placeholder="Branch Type"
                      name="transferFee"
                      className="form-control"
                      value={branchType}
                      onChange={(e) => setBranchType(e.target.value)}
                    >
                    </input>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">Related To Extra Info</label>
                    <input
                      type="text"
                      placeholder="Related To Extra Info"
                      name="transferFee"
                      className="form-control"
                      value={relatedToExtraInfo}
                      onChange={(e) => setRelatedToExtraInfo(e.target.value)}
                    >
                    </input>
                  </div>

                </form>

              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateBranchAccount(e)} >
            {t('crud_general_save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddBranchAccount