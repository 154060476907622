import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import TransferTransactionFeeService from '../../services/TransferTransactionFeeService'
import UserService from '../../services/UserService';

const AddNewPrice = ({ showAddPriceModal, setShowAddPriceModal, id, setId, }) => {

  let idEdit = id


  const [fromCountry, setFromCountry] = useState('')
  const [toCountry, setToCountry] = useState('')
  const [fee, setFee] = useState('')
  const [feeType, setFeeType] = useState('')
  const [feeDelm, setFeeDelm] = useState('')
  const [rangeValue, setRangeValue] = useState('')
  const [rangeValueMin, setRangeValueMin] = useState('')
  const [rangeValueMax, setRangeValueMax] = useState('')
  const [allowedCountries, setAllowedCountries] = useState([])
  // const navigate = useNavigate();
  // const { id } = useParams();

  const saveOrUpdateTransferTransactionFee = (e) => {
    e.preventDefault();

    const transferFee = { fromCountry, toCountry, fee, feeType, feeDelm, rangeValue, rangeValueMin, rangeValueMax }

    if (idEdit) {
      TransferTransactionFeeService.updateTransferTransactionFee(idEdit, transferFee).then((response) => {
        // navigate('/transfer-fees')
        setShowAddPriceModal(false)
      }).catch(error => {
        console.log(error)
      })
    } else {
      TransferTransactionFeeService.createTransferTransactionFee(transferFee).then((response) => {
        console.log(response.data)
        setShowAddPriceModal(false)
        // navigate('/transfer-fees');
      }).catch(error => {
        console.log(error)
      })
    }
  }

  const handleClose = () => {
    setShowAddPriceModal(false)
    setId(null)
    idEdit = null
    setFromCountry("")
    setToCountry("")
    setFee("")
    setFeeDelm("")
    setFeeType("")
    setRangeValue("")
    setRangeValueMin("")
    setRangeValueMax("")
  };

  useEffect(() => {
    if (idEdit) {
      TransferTransactionFeeService.getTransferTransactionFee(idEdit).then((response) => {
        setFromCountry(response.data.fromCountry)
        setToCountry(response.data.toCountry)
        setFee(response.data.fee)
        setFeeDelm(response.data.feeDelm)
        setFeeType(response.data.feeType)
        setRangeValue(response.data.rangeValue)
        setRangeValueMin(response.data.rangeValueMin)
        setRangeValueMax(response.data.rangeValueMax)
      }).catch(error => {
        console.log(error)
      })
    } else {
      UserService.getAllowedCountries().then((response) => {
        setAllowedCountries(response.data)
      }).catch(error => {
        console.log(error)
      })
    }
  }, [idEdit, showAddPriceModal])

  const title = () => {

    if (idEdit) {
      return <h2 className="text-center">{t('transfer_fee_update')}</h2>
    } else {
      return <h2 className="text-center">{t('transfer_fee_add_new')}</h2>
    }
  }

  return (
    <>
      <Modal show={showAddPriceModal} onHide={handleClose} size="md" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="card col-md-12">
              <div className="card-body">
                <form className='p-0'>
                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_from_country')}</label>
                    <select
                      name="fromCountry"
                      placeholder="Choose From Country"
                      className="form-control"
                      value={fromCountry}
                      onChange={(e) => setFromCountry(e.target.value)}>
                      {
                        allowedCountries.map(
                          allowedCountry =>
                            <option key={allowedCountry.countryName} value={allowedCountry.countryName}>{allowedCountry.countryName}</option>)
                      }
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_to_country')}</label>
                    <select
                      name="toCountry"
                      placeholder="Choose To Country"
                      className="form-control"
                      value={toCountry}
                      onChange={(e) => setToCountry(e.target.value)}>
                      {
                        allowedCountries.map(
                          allowedCountry =>
                            <option key={allowedCountry.countryName} value={allowedCountry.countryName}>{allowedCountry.countryName}</option>)
                      }
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_value_is')}</label>
                    <select
                      name="toCountry"
                      placeholder="Choose To Country"
                      className="form-control"
                      value={feeDelm}
                      onChange={(e) => setFeeDelm(e.target.value)}>
                      <option value="EQUAL">{t('transfer_fee_value_is_equal')}</option>
                      <option value="BETWEEN">{t('transfer_fee_value_is_between')}</option>
                      <option value="GREATER">{t('transfer_fee_value_is_greater')}</option>
                      <option value="LESS">{t('transfer_fee_value_is_less')}</option>
                    </select>
                  </div>
                  {feeDelm == "" || feeDelm == "EQUAL" || feeDelm == "GREATER" || feeDelm == "LESS" ?
                    (
                      <div className="form-group mb-2">
                        <label className="form-label">{t('transfer_fee_value_is_compared_to_value')}</label>
                        <input
                          type="text"
                          placeholder={t('transfer_fee_value_is_compared_to_value')}
                          name="transferFee"
                          className="form-control"
                          value={rangeValue}
                          onChange={(e) => setRangeValue(e.target.value)}
                        >
                        </input>
                      </div>
                    ) : (
                      <div>

                        <div className="form-group mb-2">
                          <label className="form-label">{t('transfer_fee_value_is_compared_to_value_min')}</label>
                          <input
                            type="text"
                            placeholder={t('transfer_fee_value_is_compared_to_value_min')}
                            name="transferFee"
                            className="form-control"
                            value={rangeValueMin}
                            onChange={(e) => setRangeValueMin(e.target.value)}
                          >
                          </input>
                        </div>

                        <div className="form-group mb-2">
                          <label className="form-label">{t('transfer_fee_value_is_compared_to_value_max')}</label>
                          <input
                            type="text"
                            placeholder={t('transfer_fee_value_is_compared_to_value_max')}
                            name="transferFee"
                            className="form-control"
                            value={rangeValueMax}
                            onChange={(e) => setRangeValueMax(e.target.value)}
                          >
                          </input>
                        </div>
                      </div>
                    )}

                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_type')}</label>
                    <select
                      name="toCountry"
                      placeholder="Choose To Country"
                      className="form-control"
                      value={feeType}
                      onChange={(e) => setFeeType(e.target.value)}>

                      <option value="PERCENTAGE">{t('transfer_fee_type_percentage')}</option>
                      <option value="VALUE">{t('transfer_fee_type_value')}</option>

                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_value')}</label>
                    <input
                      type="text"
                      placeholder={t('transfer_fee_value')}
                      name="transferFee"
                      className="form-control"
                      value={fee}
                      onChange={(e) => setFee(e.target.value)}
                    >
                    </input>
                  </div>
                  {/* <button className="btn btn-success" onClick={(e) => saveOrUpdateTransferTransactionFee(e)} >Submit </button>
                  <Link to="/transfer-fees" className="btn btn-danger"> Cancel </Link> */}
                </form>

              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateTransferTransactionFee(e)} >
            {t('crud_general_save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddNewPrice