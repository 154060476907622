import jwt_decode from 'jwt-decode';
import { notify } from '../../components/Toast';
import { authService } from "./AuthService";

const API_URL = process.env.REACT_APP_API_URL

const signIn = async (loginData) => {

    // Send data to the backend via POST
    let response = await fetch(`${API_URL}/api/auth/login`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(loginData)

    });
    return await response.json();
};

const signOn = async (registerData) => {

    return fetch(`${API_URL}/api/auth/register`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(registerData)

    });
}

const confirmRegistration = async (token) => {

    return fetch(`${API_URL}/api/auth/registration-confirm?token=${token}`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
}

const resetPassword = async (email) => {

    const response = await fetch(`${API_URL}/api/auth/reset-password?email=${email}`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    return await handleApiResponse(response);
}

const changePassword = async (passwordChangeRequest) => {

    const response = await fetch(`${API_URL}/api/auth/change-password`, {

        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(passwordChangeRequest)
    });
    //
    // console.log(response)
    // const body = await response.json();
    // console.log(body)
    // return body;
    return await handleApiResponse(response);
}

export const updateAccessToken = async () => {

    let refreshToken = getRefreshToken();

    let response = await fetch(`${API_URL}/api/auth/refresh-token?token=${refreshToken}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });

    if (response.status === 200) {
        let res = await response.json();

        // console.log(res);
        return validateAndSaveTokens(res);
    } else if (response.status === 401 || response.status === 403) {
        authService.logout();
        const msg = "Refresh token expired or account blocked. Try to login again or call administration";
        notify({ info: msg, status: "success" });

        // alert(msg);

        let body = await response.json();
        console.log(body);
        throw new Error(msg);
    } else {
        let body = await response.json();
        let errorMsg = body.message ? body.message : JSON.stringify(body);
        notify({ info: errorMsg, status: "failed" });

        // alert(errorMsg);
    }

}

function validateAndSaveTokens(responseFromApi) {
    if (responseFromApi?.access_token && responseFromApi?.refresh_token) {
        let jwtDecoded = jwt_decode(responseFromApi.access_token);
        let refreshJwtDecoded = jwt_decode(responseFromApi.refresh_token);
        if (jwtDecoded.sub && jwtDecoded.userId && !isTokenExpired(responseFromApi.access_token)) {
            localStorage.setItem('access_token', responseFromApi.access_token);
        }
        if (refreshJwtDecoded.sub && refreshJwtDecoded.userId && !isTokenExpired(responseFromApi.refresh_token)) {
            localStorage.setItem('refresh_token', responseFromApi.refresh_token);
        }
        authService.updateLoggedInUserInfo(responseFromApi.user);
        return responseFromApi.access_token;
    }
}

export function deleteUserDataAndTokens() {
    authService.logout()
}

export function isTokenExpired(token) {
    let isExpired = false;

    const timeMarginSec = 5;

    let decodedToken = jwt_decode(token);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();

    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < (currentDate.getTime() - timeMarginSec)) {
        console.log("Token expired.");
        isExpired = true;
    }

    return isExpired;
}

export async function getAccessToken() {
    let access_token = localStorage.getItem('access_token');
    if (isTokenExpired(access_token)) {
        console.log("Token expired. Requesting new...")
        await updateAccessToken();
        console.log("new AccessToken received");
        access_token = localStorage.getItem('access_token');
    }
    return access_token;
}

function getRefreshToken() {

    const refreshToken = localStorage.getItem('refresh_token');

    if (isTokenExpired(refreshToken)) {
        authService.logout();
        throw new Error("Refresh token expired. Relogin please");
    }
    return refreshToken;
}

const getAllProperties = async () => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/properties`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const handleApiResponse = async (response) => {
    const status = response.status;
    const body = await response.json();
    if (status === 200) {
        return body;
    } else if (status === 401) {
        handle401();
    } else {
        let errorMsg = body.message ? body.message : JSON.stringify(body);
        notify({ info: errorMsg, status: "failed" });

        // alert(errorMsg);
    }
}

const handle401 = () => {
    //do nothing for now
}

const getAllUsers = async () => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/admin/users`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}


const bookProperty = async (propertyId) => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/properties/${propertyId}/book`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const getUserById = async (userId) => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/users/${userId}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const updateUser = async (user) => {

    let userId = user.id;

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/users/${userId}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(user)
    });
    return await handleApiResponse(response);
}

const updateProperty = (userId, property) => changeProperty(userId, property, 'PUT');

const createProperty = (userId, property) => changeProperty(userId, property, 'POST');

async function changeProperty(userId, property, method) {

    try {
        let access_token = await getAccessToken();
        let response = await fetch(`${API_URL}/api/v1/users/${userId}/property`, {
            method: method,
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${access_token}`
            },
            body: JSON.stringify(property)
        });
        let res = await response.json();
        console.log(res);
        return res;
    } catch (error) {
        return console.log(error);
    }
}

const getAllRoles = async () => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/admin/roles`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const assignRolesToUser = async (userId, roleIds) => {

    const accessToken = await getAccessToken();

    let requestParams = '';
    roleIds.forEach(roleId => {
        if (requestParams) requestParams += '&';
        requestParams += `roleId=${roleId}`;
    });
    if (requestParams) requestParams = '?' + requestParams;

    const response = await fetch(`${API_URL}/api/v1/admin/users/${userId}/roles${requestParams}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const getAllAuthorities = async () => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/admin/authorities`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const getUserSeparateAuthorities = async (userId) => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/admin/users/${userId}/authorities`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const assignSeparateAuthoritiesToUser = async (userId, authorityIds) => {

    const accessToken = await getAccessToken();

    let requestParams = '';
    authorityIds.forEach(authorityId => {
        if (requestParams) requestParams += '&';
        requestParams += `authorityId=${authorityId}`;
    });
    if (requestParams) requestParams = '?' + requestParams;

    const response = await fetch(`${API_URL}/api/v1/admin/users/${userId}/authorities${requestParams}`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });
    return await handleApiResponse(response);
}

const createRole = async (roleName, authorityIds) => {

    const accessToken = await getAccessToken();

    const modifyRoleRequest = {
        name: roleName,
        authorities: authorityIds.map(authId => ({ id: authId }))
    };

    const response = await fetch(`${API_URL}/api/v1/admin/roles`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(modifyRoleRequest)
    });
    return await handleApiResponse(response);
}

const deleteRole = async (role) => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/admin/roles/${role.id}`, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Accept': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        }
    });

    const status = response.status;
    if (status !== 200) {
        const body = await response.json();
        let errorMsg = body.message ? body.message : JSON.stringify(body);
        notify({ info: errorMsg, status: "failed" });

        // alert(errorMsg);
    }
};

const getProfileImage = async (userId) => {

    const accessToken = await getAccessToken();

    const response = await fetch(`${API_URL}/api/v1/users/${userId}/image`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    const status = response.status;

    if (status === 200) {

        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        // console.log(imageObjectURL);
        return imageObjectURL;
    } else if (status === 401) {
        handle401();
    } else {
        const body = await response.json();
        let errorMsg = body.message ? body.message : JSON.stringify(body);
        notify({ info: errorMsg, status: "failed" });

        // alert(errorMsg);
    }

};

const changeProfileImage = async (userId, file) => {

    const accessToken = await getAccessToken();

    const formData = new FormData();
    formData.append('profileImage', file);
    const response = await fetch(`${API_URL}/api/v1/users/${userId}/image`, {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        body: formData
    });

    const status = response.status;

    if (status === 200) {
    } else if (status === 401) {
        handle401();
    } else {
        const body = await response.json();
        let errorMsg = body.message ? body.message : JSON.stringify(body);
        notify({ info: errorMsg, status: "failed" });

        // alert(errorMsg);
    }

};

export const apiClient = {
    signIn,
    signOn,
    confirmRegistration,
    resetPassword,
    changePassword,

    getAllUsers,
    getUserById,
    updateUser,

    getProfileImage,
    changeProfileImage,

    getAllProperties,
    createProperty,
    updateProperty,
    bookProperty,

    getAllRoles,
    createRole,
    deleteRole,

    getAllAuthorities,
    getUserSeparateAuthorities,

    assignRolesToUser,
    assignSeparateAuthoritiesToUser

};
