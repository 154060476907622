import { Today } from '@material-ui/icons';
import axios from 'axios'
import { getAccessToken } from './auth/ApiClient';
import Helper from './ServiceHelperUtil'

const REPORT_BASE_REST_API_URL = Helper.getBaseURL() + '/api/v1/report';

class ReportingService {

    async getAccountTransactionsReport(currentAccountId, fromDate, toDate) {
        const accessToken = await getAccessToken();
        return axios.get(REPORT_BASE_REST_API_URL + '/accountTransactions/' + currentAccountId + '/' + fromDate + '/' + toDate
            , 
            {
                ResponseType: 'blob',
                setTimeout: 3000,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }

    async getClientCurrentAccountsBalanceValidationReport(clientId) {
        const accessToken = await getAccessToken();
        return axios.get(REPORT_BASE_REST_API_URL + '/clientAccountsValidation/' + clientId
            , 
            {
                ResponseType: 'blob',
                setTimeout: 3000,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }
    //get-general-accounts-balance

    async getAccountsGeneralBalance() {
        const accessToken = await getAccessToken();
        return axios.get(REPORT_BASE_REST_API_URL + '/get-general-accounts-balance'
            , 
            {
                ResponseType: 'blob',
                setTimeout: 3000,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
    }
    async getDummyReport() {
        const accessToken = await getAccessToken();
        return axios.get(REPORT_BASE_REST_API_URL + '/dummy',  {

            ResponseType: 'blob',
            setTimeout: 3000,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf',
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }
    /**
        getAllUsers(){
            return axios.get(USER_BASE_REST_API_URL)
        }
    
        getClientsAllowedForCurrentAccounts(){
            return axios.get(USER_BASE_REST_API_URL + '/clientsWithAccounts')
        }
    
        createUser(user){
            return axios.post(USER_BASE_REST_API_URL, user)
        }
    
        getUsersByRoleType(roleType){
            return axios.get(USER_BASE_REST_API_URL +  '/role' + '/' + roleType);
        }
    
        getUsersById(userId){
            return axios.get(USER_BASE_REST_API_URL + '/' + userId);
        }
    
        updateUser(userId, user){
            return axios.put(USER_BASE_REST_API_URL + '/' +userId, user);
        }
    
        deleteUser(userId){
            return axios.delete(USER_BASE_REST_API_URL + '/' + userId);
        }
        */
}

export default new ReportingService();