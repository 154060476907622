import React, { useEffect, useState } from 'react';
import UserService from '../services/UserService';
import HeaderHome from '../components/notAuth/HeaderHome';

const HomeUser = () => {


  return (
    <>
      <HeaderHome />
    </>
  );
};

export default HomeUser;
