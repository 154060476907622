import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import App from "./App";
//import 'flag-icon-css/css/flag-icon.min.css';

import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/AuthProvider";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)

    /*
    No need for resources since we are using http backend loading of translation files
     resources: {
       en: {
         translation: 
       },
       fr: {
         translation:{
           "Welcome to React": "French Welcome"
         }
       }
     },
     */
    //No need for lng if you are using detection with order.. Ahmed
    // lng:   document.querySelector('html').lang, // "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    detection:{
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
      cookie: {
        name: "test",
      }
    },


    // Set the name of the cookie used by i18next
    // cookieName: 'myCustomCookieName',

    // react: {
    //  useSuspense: false,
    //}


  });

/*
No need for this -- this coming from i18next default configuration
function App() {
const { t } = useTranslation();

return <h2>{t('welcome_to_react')}</h2>;
}
*/

// append app to dom
/* Temporary language
const root = createRoot(document.getElementById('root'));
root.render(
  <App />
);
*/
const loadingMarkup = (
  <div className="py-4 text-center">
    <h3>Loading..</h3>
  </div>
)

ReactDOM.render(
  //<React.StrictMode>
  //<Provider store={store}>
  //  <App />
  //</Provider>
  //</React.StrictMode>,
  <Suspense fallback={loadingMarkup}>
    {/* <React.StrictMode> */}
    <AuthProvider>
      <App />
    </AuthProvider>
    {/* </React.StrictMode> */}
  </Suspense>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



