import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CurrentAccountService from '../../services/CurrentAccountService';
import { useTranslation } from "react-i18next";

const DeleteAccount = ({ sureDelete, setSureDelete, setCurrentAccounts, userId }) => {
  const { t } = useTranslation();

  const getAllCurrentAccounts = () => {
    CurrentAccountService.getAllCurrentAccounts().then((response) => {
      setCurrentAccounts(response.data)
    }).catch(error => {
      console.log(error);
    })
  }

  const deleteCurrentAccount = (currentAccountId) => {
    CurrentAccountService.deleteCurrentAccount(currentAccountId).then((response) => {
      getAllCurrentAccounts();
    }).catch(error => {
      console.log(error);
    })
  }


  return (
    <div>

      <Modal show={sureDelete} onHide={() => setSureDelete(false)} size="md" centered>
        <Modal.Header>
          <Modal.Title>are you sure to delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSureDelete(false)}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => deleteCurrentAccount(userId)} >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default DeleteAccount