import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import TransferTransactionFeeService from '../services/TransferTransactionFeeService';
import TransferFeeHelperUtil from '../helpers/TransferFeeHelperUtil';
import HelperUtil from '../helpers/HelperUtil';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AddNewPrice from './modals/AddNewPrice';
import { authService } from '../services/auth/AuthService';

const ListTransferTransactionFeeComponent = () => {

  const [transferFees, setTransferFees] = useState([])
  const [showAddPriceModal, setShowAddPriceModal] = useState(false)
  const [id, setId] = useState(null)

  useEffect(() => {
    getAllTransferFees();
  }, [showAddPriceModal])

  const getAllTransferFees = () => {
    TransferTransactionFeeService.getAllTransferFees().then((response) => {
      setTransferFees(response.data)
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  const deleteTransferFee = (transferFeeId) => {
    //      CurrentAccountService.deleteCurrentAccount(currentAccountId).then((response) =>{
    TransferTransactionFeeService.deleteTransferTransactionFee(transferFeeId).then((response) => {
      getAllTransferFees();

    }).catch(error => {
      console.log(error);
    })

  }
  //  <Link className="btn btn-info" to={`/edit-transfer-fee/${transferFee.id}`} > {t('crud_general_update')} </Link>

  const showUpdateModal = (id) => {
    setId(id)
    setShowAddPriceModal(true)
  }

  const createCommonActions = (row) => {
    return (
      <>
        {/* <Link className="btn btn-info" to={`/edit-transfer-fee/${row["id"]}`} > {t('crud_general_update')} </Link> */}
        {authService.userHasAuthority('transferTransactionFee.update') &&
          <button className="btn btn-info" onClick={() => showUpdateModal(row["id"])} > {t('crud_general_update')} </button>
        }
        {/* to={`/edit-transfer-fee/${row["id"]}`} */}
        {authService.userHasAuthority('transferTransactionFee.delete') &&
          <button className="btn btn-danger" onClick={() => deleteTransferFee(row["id"])} disabled> {t('curd_general_delete')} </button>
        }
      </>
    )
  }


  const showAddModal = () => {
    setId(null)
    setShowAddPriceModal(true)
  }

  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
        {/* <Link to="/add-transfer-fee" className="btn btn-primary mb-2" > {t('transfer_fee_add_new')} </Link> */}
        { authService.userHasAuthority('transferTransactionFee.create') &&
          <button className="btn btn-primary mb-2" onClick={() => showAddModal()}> {t('transfer_fee_add_new')} </button>
        }
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />
      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: t('transfer_fee_id'),
      sort: true
    },
    {
      dataField: "fromCountry",
      text: t('transfer_fee_from_country'),
      sort: true
    },
    {
      dataField: "toCountry",
      text: t('transfer_fee_to_country'),
      sort: true
    },
    {
      dataField: "feeDelm",
      text: t('transfer_fee_condition'),
      sort: true,
      formatter: (cell, row) => TransferFeeHelperUtil.getTransferFeeInWrittenPatternLan(row["feeDelm"], row["rangeValue"],
        row["rangeValueMin"], row["rangeValueMax"])
    },
    {
      dataField: "feeType",
      text: t('transfer_fee_type'),
      sort: true,
      formatter: (cell, row) => HelperUtil.getFeeTypeNameLang(row["feeType"])
    },
    {
      dataField: "fee",
      text: t('transfer_fee_value'),
      //  formatter : (cell, row) => HelperUtil.getFeeTypeNameLang(row["feeType"]) 
    },
    {
      dataField: "id",
      text: t('crud_general_actions'),
      formatter: (cell, row) => createCommonActions(row)
    }
  ]




  return (
    <div className="container py-5">
      <h2 className="text-center py-3"> {t('transfer_fee_list')} </h2>
      <ToolkitProvider
        keyField="id"
        data={transferFees}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>

      <AddNewPrice showAddPriceModal={showAddPriceModal} setShowAddPriceModal={setShowAddPriceModal} id={id} setId={setId} />
    </div>
  )
}

export default ListTransferTransactionFeeComponent
