import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Logo from "../assets/ghalbanNlogo.png";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";
import "../styles/Navbar.css";
import useAuth from '../hooks/useAuth'

import { RiArrowDownSLine } from "react-icons/ri"
import { authService } from "../services/auth/AuthService";
import Dropdown from "./common/Dropdown";
import { notify } from "./Toast";
import { toast } from "react-toastify";

//const loggedIn = window.localStorage.getItem("LoggedIn");



function Navbar() {
  const navigate = useNavigate();
  const [openLinks, setOpenLinks] = useState(false);

  const [userLogged, setUserLogged] = useState()

  const toggleNavbar = () => {
    setOpenLinks(!openLinks);
  };
  const { t } = useTranslation()

  useEffect(() => {
    authService.currentUser.subscribe(x => setUserLogged(x));

    // console.log(userLogged)
  }, []);

  const location = useLocation();
  useEffect(() => {
    setOpenLinks(false);
  }, [location]);
  

  const handleLogout = () => {
    authService.logout();
    navigate('/login')
  }


  return (
    <div className="navbar ">
      <div className="container">

        <Link to="/">
          <img src={Logo} className="logo-img" />
        </Link>

        {
          userLogged ?
            <div className="d-flex justify-content-between align-items-center fullFide" >
              <div className="rightSide">

                <Link to="/"> {t('nav_home')} </Link>
                <Link to="/users"> {t('nav_users')} </Link>
                <Link to="/currentAccounts"> {t('nav_current_accounts')} </Link>
                <Link to="/transferTransactions"> {t('nav_transfer_transactions')} </Link>

                <div className="position-relative">
                  <Link to="" className="dropdown-dad" onClick={(e) => e.preventDefault()} >
                    إعدادات <RiArrowDownSLine />
                  </Link>
                  <ul className="dropdown-nav">
                    <li>
                      <Link to="/exchange-rates">{t('nav_exchange_rates')}</Link>
                    </li>
                    <li>
                      <Link to="/transfer-fees"> {t('nav_transfer_fees')}</Link>
                    </li>
                    <li>
                      <Link to="/user-transfer-fees">{t('nav_user_transfer_fees')}</Link>
                    </li>
                    <li>
                      <Link to="/branch-accounts">{t('branch_accounts')}</Link>
                    </li>
                  </ul>
                </div>

                {/* <Link to="/">{t('nav_balance')}</Link> */}
                <button onClick={toggleNavbar}>
                  <ReorderIcon />
                </button>
              </div>

              <div className="leftSide" id={openLinks ? "open" : "close"}>
                <Dropdown />
                <div className="dropdown">
                  <button className="btn btn-secondary dropdown-toggle px-3 d-flex justify-content-between align-items-center" style={{ background: "#1b77fd" }} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    {JSON.parse(localStorage.getItem('currentUser')).username}
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li>
                      <Link className="dropdown-item nav-link" to={`/profileMe/${authService.currentUserValue.id}`}>{t("nav_Profile_word")}</Link>
                    </li>
                    <li>
                      <Link to='#' className='dropdown-item nav-link' onClick={handleLogout}>
                        {t("nav_logout")}
                      </Link>
                    </li>
                  </ul>
                </div>

                {/*  */}
                <div className="hiddenLinks">
                  <Link to="/"> {t('nav_home')} </Link>
                  <Link to="/users"> {t('nav_users')} </Link>
                  <Link to="/currentAccounts"> {t('nav_current_accounts')} </Link>
                  <Link to="/transferTransactions"> {t('nav_transfer_transactions')} </Link>
                  <Link to="/exchange-rates">{t('nav_exchange_rates')}</Link>
                  <Link to="/transfer-fees"> {t('nav_transfer_fees')} </Link>
                  <Link to="/user-transfer-fees">{t('nav_user_transfer_fees')}</Link>
                  <Link to="/">{t('nav_balance')}</Link>
                </div>
              </div>
            </div>

            :

            <div className="d-flex justify-content-between align-items-center fullFide" >
              <div className="rightSide">
                <Link to="/homeUser"> {t('nav_homeUser')} </Link>
                <Link to="/aboutUs">{t('nav_aboutUs')}</Link>
                <Link to="/services">{t('nav_services')}</Link>
                <Link to="/exchangeRates">{t('nav_exchangeRates')}</Link>
                <Link to="/countryFees">{t('nav_countryFees')}</Link>
                <Link to="/contact">{t('nav_contact')}</Link>

                <button onClick={toggleNavbar}>
                  <ReorderIcon />
                </button>
              </div>
              {/* mobile */}
              <div className="leftSide" id={openLinks ? "open" : "close"}>
                <p className="m-0 p-0">
                  <Link to="/login" className="m-0">
                    {t("nav_login")}
                  </Link>
                </p>
                <Dropdown />

                {/*  */}
                <div className="hiddenLinks">
                  <Link to="/homeUser"> {t('nav_homeUser')} </Link>
                  <Link to="/aboutUs">{t('nav_aboutUs')}</Link>
                  <Link to="/sendMoney">{t('nav_sendMoney')}</Link>
                  <Link to="/services">{t('nav_services')}</Link>
                  <Link to="/exchangeRates">{t('nav_exchangeRates')}</Link>
                  <Link to="/contact">{t('nav_contact')}</Link>
                </div>
              </div>
            </div>
        }

      </div>

    </div >
  );
}

export default Navbar;
