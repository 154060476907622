import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ExchangeRateService from '../services/ExchangeRateService';
import Moment, { format } from 'moment';
import HelperUtil from '../helpers/HelperUtil';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AddNewPriceRate from './modals/AddNewPriceRate';
import { authService } from '../services/auth/AuthService';



const ListExchangeRateComponent = () => {

  const [exchangeRates, setExchangeRates] = useState([])
  const { t } = useTranslation()

  const [showAddNewPrice, setShowAddNewPrice] = useState(false)
  const [idForEdit, setIdForEdit] = useState(null)

  useEffect(() => {
    getAllExchangeRates();
  }, [showAddNewPrice])

  const getAllExchangeRates = () => {
    ExchangeRateService.getAllExchangeRates().then((response) => {
      setExchangeRates(response.data)
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  const editXchangeRate = (idEdit) => {
    setIdForEdit(idEdit)
    setShowAddNewPrice(true)
  }

  const createCommonActions = (row) => {
    return (
      <div>
        {/* <Link className="btn btn-info" to={`/edit-exchange-rate/${row["id"]}`} style={{ marginLeft: "10px", color: "#fff" }}> {t('crud_general_update')} </Link> */}
        {authService.userHasAuthority('exchangeRate.update') &&
          <button className="btn btn-info" onClick={() => editXchangeRate(row["id"])}> {t('crud_general_update')} </button>
        }
        {/* <button className = "btn btn-danger" onClick = {() => deleteExchangeRate(row["id"])}
        style = {{marginLeft:"10px"}}> {t('curd_general_delete')}</button>
        */}
        {authService.userHasAuthority('exchangeRate.delete') &&
          <button className="btn btn-danger" disabled style={{ marginLeft: "10px" }}> {t('curd_general_delete')}</button>
        }
      </div>
    )
  }

  const displayExchangeRateStatus = (status) => {
    if (status === "ACTIVE") {
      return t('exchange_rate_active')
    }
    if (status === "IN_ACTIVE") {
      return t('exchange_rate_inactive')
    }
    else {
      return "";
    }
  }


  const deleteExchangeRate = (exchangeRateId) => {
    ExchangeRateService.deleteExchangeRate(exchangeRateId).then((response) => {
      //   ExchangeRateService.getAllExchangeRates.then((response) =>{
      getAllExchangeRates();
    }).catch(error => {
      console.log(error);
    })
  }

  const showModalNewPrice = () => {
    setIdForEdit(null)
    setShowAddNewPrice(true)
  }

  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
        {/* <Link to="/add-exchange-rate" className="btn btn-primary mb-2" > {t('exchange_rate_add_new')} </Link> */}
        {authService.userHasAuthority('exchangeRate.create') &&
          <button className="btn btn-primary mb-2" onClick={() => showModalNewPrice()}> {t('exchange_rate_add_new')} </button>
        }
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />

      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: t('exchange_rate_id'),
      sort: true
    },
    {
      dataField: "fromCurrency",
      text: t('exchange_rate_from_currency'),
      sort: true
    },
    {
      dataField: "toCurrency",
      text: t('exchange_rate_to_currency'),
      sort: true
    },
    {
      dataField: "rate",
      text: t('exchange_rate_value'),
      sort: true,
      //           formatter: (cell, row) => HelperUtil.getCurrencyNameLocale(row["accountCurrency"])
    },
    {
      dataField: "date",
      text: t('date'),
      sort: true,
      formatter: (cell, row) => HelperUtil.formatDate(row["date"])
    },
    {
      dataField: "status",
      text: t('exchange_rate_status_title'),
      formatter: (cell, row) => displayExchangeRateStatus(row["status"])
    },
    {
      dataField: "id",
      text: t('crud_general_actions'),
      formatter: (cell, row) => createCommonActions(row)
    }
  ]


  return (

    <div className="container py-5">
      <h2 className="text-center py-3"> {t('current_account_list')} </h2>

      <ToolkitProvider
        keyField="id"
        data={exchangeRates}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>

      <AddNewPriceRate
        showAddNewPrice={showAddNewPrice} setShowAddNewPrice={setShowAddNewPrice}
        idForEdit={idForEdit} setIdForEdit={setIdForEdit}
      />
    </div>
  )
}

export default ListExchangeRateComponent
