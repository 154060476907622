import React from 'react'
import { useState, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { authService } from '../services/auth/AuthService';

function Protected({ isSignedIn, children }) {
  const [loggedInUser, setLoggedInUser] = useState(authService.currentUserValue);
  // const [isAdmin, SetIsAdmin] = useState(authService.currentUserValue.roles.includes("ADMIN") || false)

  useEffect(() => {
    authService.currentUser.subscribe(x => setLoggedInUser(x));

    // const subscription = authService.currentUser.subscribe(user => {
    //   setLoggedInUser(user);
    // });

    // return () => {
    //   subscription.unsubscribe();
    // };

  }, [authService]);


  const isAuthenticated = !!loggedInUser;
  // console.log("🚀 ~ file: Protected.jsx:16 ~ Protected ~ isAuthenticated", isAuthenticated);


  useEffect(() => {
    // if (!isAuthenticated) {
    //   navigate("/admin/login");
    // }    
  }, [isAuthenticated]);


  if (!isAuthenticated) {
    return <Navigate to={`/homeUser`} replace />
  }
  else {
    return children
  }

}
export default Protected


