import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import TransferTransactionService from '../services/TransferTransactionService'
import useAuth from '../hooks/useAuth'
import { authService } from '../services/auth/AuthService';


const ListTransferTransactionComponent = () => {

    const [transferTransactions, setTransferTransactions] = useState([])
    const { auth } = useAuth();
    const { t } = useTranslation()

    let id = authService.currentUserValue.id

    useEffect(() => {
        getLoggedUserTransactions();
    }, [])

    const getLoggedUserTransactions = () => {
        console.log(id);
        // auth?.user
        TransferTransactionService.getAllTransferTransactions(id)
            .then((response) => {
                setTransferTransactions(response.data)
                console.log(response.data);
            }).catch(error => {
                console.log(error);
            })
    }

    const deleteTransferTransaction = (transferTransactionId) => {
        TransferTransactionService.deleteTransferTransaction(transferTransactionId).then((response) => {
            getLoggedUserTransactions();
        }).catch(error => {
            console.log(error);
        })

    }

    return (
        <div className="container">
            <h2 className="text-center"> {t('transfer_transaction_list')} </h2>
            <Link to="/add-transferTransaction" className="btn btn-primary mb-2" > {t('transfer_transaction_add_new')} </Link>
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th> {t('transfer_transaction_id')} </th>
                        <th> {t('transfer_transaction_id')} </th>
                        <th> {t('transfer_transaction_sender_name')} </th>
                        <th> {t('transfer_transaction_receiver_name')} </th>
                        <th> {t('transfer_transaction_broker_name')} </th>
                        <th> {t('transfer_transaction_created_by')} </th>
                        <th> {t('transfer_transaction_sent_amount')} </th>
                        <th> {t('transfer_transaction_sent_currency')} </th>
                        <th> {t('transfer_transaction_receive_amount')} </th>
                        <th> {t('transfer_transaction_receive_currecny')} </th>
                        <th> {t('transfer_transaction_status')} </th>
                        <th>  {t('crud_general_actions')} </th>
                    </tr>
                </thead>
                <tbody>
                    {/* {
                        transferTransactions.map(
                            transferTransation =>
                                <tr key={transferTransation.id}>
                                    <td> {transferTransation.id} </td>
                                    <td>{transferTransation.transactionIdentifier}</td>
                                    <td> {transferTransation.senderName} </td>
                                    <td> {transferTransation.receiverName} </td>
                                    <td>{transferTransation.broker.businessName}</td>
                                    <td>{transferTransation.requestedByUserName}</td>
                                    <td> {transferTransation.depositAmount}</td>
                                    <td> {transferTransation.depositCurrency}</td>
                                    <td> {transferTransation.receivedAmount}</td>
                                    <td> {transferTransation.receivedCurrency}</td>
                                    <td>{transferTransation.transactionStatus}</td>
                                    <td>
                                        <Link className="btn btn-info" to={`/edit-transferTransaction/${transferTransation.id}`} >Update</Link>
                                        <button className="btn btn-danger" onClick={() => deleteTransferTransaction(transferTransation.id)}
                                            style={{ marginLeft: "10px" }}> Delete</button>
                                    </td>
                                </tr>
                        )
                    } */}
                </tbody>
            </table>
        </div>
    )
}

export default ListTransferTransactionComponent
