import { t } from 'i18next';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import CurrentAccountTransactionService from '../services/CurrentAccountTransactionService';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { dateFilter, textFilter } from 'react-bootstrap-table2-filter';
//import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import Moment, { format } from 'moment';
import HelperUtil from '../helpers/HelperUtil';
import CurrentAccountService from '../services/CurrentAccountService';
import ReportingService from '../services/ReportingService';
import FileDownloaderHelperUtil from '../helpers/FileDownloaderHelperUtil';
import AddNewMoveFromMain from './modals/AddNewMoveFromMain';
import { authService } from '../services/auth/AuthService';

const ListCurrentAccountTransactionComponent = () => {

  const [currentAccountTransactions, setCurrentAccountTransactions] = useState([])
  const [clientAccounts, setClientAccounts] = useState([])
  const [currentAccountId, setCurrentAccountId] = useState('');
  const [clientId, setClientId] = useState('')
  const [clientName, setClientName] = useState('')
  const [reportFromDate, setReportFromDate] = useState('')
  const [reportToDate, setReportToDate] = useState('')

  const navigate = useNavigate()
  const { id } = useParams()
  const params = useParams()

  const [addMove, setAddMove] = useState(false)
  const [accountId, setAccountId] = useState(null)
  const [idUpdate, setIdUpdated] = useState(null)


  useEffect(() => {
    getCurrentAccountTransactions();
  }, [addMove])

  const getCurrentAccountTransactions = () => {
    if (id) {
      CurrentAccountTransactionService.getAccountTransactions(id).then((response) => {
        setCurrentAccountTransactions(response.data);
        setCurrentAccountId(response?.data[0]?.currentAccountId);
        console.log(response?.data[0])

        setClientId(response?.data[0]?.clientId);
        setClientName(response?.data[0]?.accountHolderName);

        // console.log("Client Id", clientId)
        // console.log("Client Id from Data", response?.data[0]?.clientId)

        CurrentAccountService.getClientCurrentAccounts(response?.data[0].clientId).then((response) => {
          setClientAccounts(response.data);
          //   setCurrentAccountId(response?.data[0]?.currentAccountId);
        }).catch(error => {
          console.log(error);
        })
      }).catch(error => {
        console.log(error);
      })
    }
  }


  const deleteCurrentAccountTransaction = (id) => {
    CurrentAccountTransactionService.deleteCurrentAccountTransaction(id).then((response) => {
      getCurrentAccountTransactions();
    }).catch(error => {
      console.log(error);
    })
  }

  const moveToAnotherClientAccount = (accountId) => {
    navigate('/currentAccountTransactions/' + accountId);
    window.location.reload(false);
  }



  const getTransactionTypeLocale = (transactionType, transactionStatus) => {
    if (transactionType === "WITHDRAW" && transactionStatus === 'CANCELLED') {
      return (<span style={{ color: "green", fontWeight: "bold", textDecoration: "line-through" }}> {t('withdraw')} </span>);
    }
    else if (transactionType === "WITHDRAW") {
      return (<span style={{ color: "green", fontWeight: "bold" }}> {t('withdraw')} </span>);
    }
    else if (transactionType === "DEPOSIT" && transactionStatus === 'CANCELLED') {
      return (<span style={{ color: "red", fontWeight: "bold", textDecoration: "line-through" }}> {t('deposit')} </span>);
      //  return t('deposit')
    }
    else if (transactionType === "DEPOSIT") {
      return (<span style={{ color: "red", fontWeight: "bold" }}> {t('deposit')} </span>);
    }
  }

  const displayTransactionAmount = (transactionAmount, transactionType, transactionStatus) => {
    if (transactionType === "WITHDRAW" && transactionStatus === 'CANCELLED') {
      return (<span style={{ color: "green", fontWeight: "bold", textDecoration: "line-through" }}> {transactionAmount} </span>);
    }
    else if (transactionType === "WITHDRAW") {
      return (<span style={{ color: "green", fontWeight: "bold" }}> {transactionAmount} </span>);
    }
    else if (transactionType === "DEPOSIT" && transactionStatus === 'CANCELLED') {
      return (<span style={{ color: "red", fontWeight: "bold", textDecoration: "line-through" }}> {transactionAmount} </span>);
      //  return t('deposit')
    }
    else if (transactionType === "DEPOSIT") {
      return (<span style={{ color: "red", fontWeight: "bold" }}> {transactionAmount} </span>);
    }
  }

  const showUpdateMove = (idU) => {
    setIdUpdated(idU)
    setAddMove(true)
  }

  const createCommonActions = (row) => {
    if(row["createdByUserName"] === 'systemUser') {
      return "-";
    }
    else{
    return (
      <div>
        {/* <Link className="btn btn-info" to={`/edit-currentAccountTransaction/${row["id"]}`}
          style={{ marginLeft: "10px" }} > تعديل رابط </Link> */}
        {authService.userHasAuthority('currentAccountTransaction.update') &&
          <button className="btn btn-info mx-1" onClick={() => showUpdateMove(row["id"])}> {t('crud_general_update')} </button>
        }
        { authService.userHasAuthority('currentAccountTransaction.delete') && 
          <button className="btn btn-danger" disabled onClick={() => deleteCurrentAccountTransaction(row["id"])}> حدف </button>
        }
      </div>

      
    )
      } //else
  }

  const formatDate = (row) => {
    if (row["transactionStatus"] === 'CANCELLED') {
      return (<span style={{ textDecoration: "line-through" }}>{(Moment(row["executionDate"]).format('MMMM Do YYYY, h:mm:ss a'))} </span>);
    }
    else {
      return (<span>{(Moment(row["executionDate"]).format('MMMM Do YYYY, h:mm:ss a'))} </span>);
    }
  }

  const formatAccountBalanceBeforAndAfter = (value, transactionStatus) => {
    if (transactionStatus === 'CANCELLED') {
      return t('current_account_transactio_status_cancelled');
    }
    else { return value; }

  }

  const formatNotes = (row) => {
    if (row["transactionStatus"] === 'ACTIVE') {
      return row["notes"];
    }

    if (row["transactionStatus"] === 'CANCELLED') {
      return t('current_account_transactio_status_cancelled') + ": " + row["reasonForCancelling"] + ": " +
        (Moment(row["statusChangeDate"]).format('MMMM Do YYYY, h:mm:ss a'))
      //   row["statusChangeDate"]
    }
    if (row["transactionStatus"] === 'REACTIVATED') {
      return t('current_account_transactio_status_reactivated') + ": " + row["reasonForReactivation"] + ": " +
        (Moment(row["statusChangeDate"]).format('MMMM Do YYYY, h:mm:ss a'))
      //     row["statusChangeDate"]
    }
  }

  const generateTransactionsReport = () => {
    if (reportFromDate && reportToDate && reportFromDate <= reportToDate) {
      //  alert("From " + reportFromDate + "To " + reportToDate);
      //   ReportingService.getDummyReport().
      ReportingService.getAccountTransactionsReport(currentAccountId, reportFromDate, reportToDate).
        then((response) => {
          var fileName = "حركات حساب" + "-" + clientName;
          FileDownloaderHelperUtil.downloadDocument(response, fileName);
        }).catch(error => {
        })

    }
    else {
      alert("تأكد من أنك اخترت فترة حركات التقارير وأن تاريخ البداية أقدم من تاريخ النهاية");
    }
  }


  const columns = [
    {
      dataField: "transactionIdentifier",
      text: t('current_account_transaction_identifier'),
      sort: true
    },
    //     {
    //        dataField : "id", //account Details
    //       text : t('current_account_transaction_account_details'),
    //       sort: true,
    //       formatter: (cell, row) => {
    //          return (
    //             <label> {HelperUtil.getCurrencyNameLocale(row["accountCurrency"])} ::: {row["accountHolderName"]} </label>

    //     )}
    // },
    {
      dataField: "createdByUserName",
      text: t('current_account_transaction_created_by'),
      sort: true
    },
    //    {
    //       dataField : "accountCurrency",
    //      text : t('current_account_transaction_currency'),
    //      sort : true,
    //     formatter : (cell, row) => HelperUtil.getCurrencyNameLocale(row["accountCurrency"])
    //   },
    {
      dataField: "transactionType",
      text: t('current_account_transaction_type'),
      sort: true,
      formatter: (cell, row) => getTransactionTypeLocale(row["transactionType"], row["transactionStatus"])
    },
    {
      dataField: "transactionAmount",
      text: t('current_account_transaction_amount'),
      sort: true,
      formatter: (cell, row) => displayTransactionAmount(row["transactionAmount"], row["transactionType"], row["transactionStatus"])
    },
    {
      dataField: "executionDate",
      text: t('current_account_transaction_Execution_Date'),
      formatter: (cell, row) => formatDate(row)
    },
    {
      dataField: "notes",
      text: t('current_account_transaction_notes'),
      formatter: (cell, row) => formatNotes(row)
    },
    {
      dataField: "balanceBeforeTransaction",
      text: t('current_account_transaction_balance_before'),
      formatter: (cell, row) => formatAccountBalanceBeforAndAfter(row["balanceBeforeTransaction"], row["transactionStatus"])
    },
    {
      dataField: "balanceAfterTransaction",
      text: t('current_account_transaction_balance_after'),
      formatter: (cell, row) => formatAccountBalanceBeforAndAfter(row["balanceAfterTransaction"], row["transactionStatus"])
    },
    {
      dataField: "id",
      text: t('crud_general_actions'),
      formatter: (cell, row) => createCommonActions(row)
    }
  ]


  const showAddNewMove = (currentAccountId) => {
    setAccountId(currentAccountId)
    setAddMove(true)
  }


  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
        {/* <Link className="btn btn-primary my-2" to={`/add-currentAccountTransaction/${currentAccountId}`} > اضافة </Link> */}
        {authService.userHasAuthority('currentAccountTransaction.create') &&
          <button className="btn btn-primary my-2" onClick={() => showAddNewMove(currentAccountId)}>
            {t('current_account_transaction_add_new')}
          </button>
        }
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />

      </div>
    );
  };

  return (
    <div className="container py-5">
      <h2 className="text-center pb-5"> {t('current_account_transaction_list')} </h2>

      <table className="table table-bordered table-striped mb-5">
        <thead>
          <tr>
            <th> {t('current_account_identifier')} </th>
            <th> {t('currency')} </th>
            <th> {t('balance')} </th>
            <th> {t('user_name')} </th>
            <th> {t('user_identifier')} </th>
            <th> {t('user_main_mobile')} </th>
          </tr>
        </thead>
        <tbody>
          <td>{currentAccountTransactions[0]?.accountIdentifier}</td>
          <td>{HelperUtil.getCurrencyNameLocale(currentAccountTransactions[0]?.accountCurrency)}</td>
          <td>{currentAccountTransactions[0]?.accountBalance}</td>
          <td>{currentAccountTransactions[0]?.accountHolderName}</td>
          <td>{currentAccountTransactions[0]?.userIdentifier}</td>
          <td>{currentAccountTransactions[0]?.mainMobileNumber}</td>
        </tbody>
      </table>


      <div className='pb-3'>
        <div className="container">
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <td> <label >{t('user_accounts')}</label></td>
                <td>
                  <select
                    name="clientAccounts"
                    placeholder="Choose Account"
                    className="form-control"
                    value={currentAccountId}
                    onChange={(e) => moveToAnotherClientAccount(e.target.value)}
                  >
                    {
                      clientAccounts.map(
                        account =>
                          <option key={account.id} value={account.id}>{HelperUtil.getCurrencyNameLocale(account.accountCurrency)}</option>)
                    }
                  </select></td>
              </tr>

              <tr>
                <td><label >{t('current_account_transactions_report')}</label></td>
                <td>
                  <td><label> من تاريخ </label></td>
                  <td> <input className="form-control" type="date" id="birthday" name="birthday" value={reportFromDate}
                    onChange={(e) => setReportFromDate(e.target.value)} /></td>
                </td>
                <td>
                  <td><label> إلى تاريخ</label></td>
                  <td> <input className="form-control" type="date" id="birthday" name="birthday" value={reportToDate}
                    onChange={(e) => setReportToDate(e.target.value)}
                  /></td>
                </td>
                <td>
                  <button className="btn btn-primary"
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => generateTransactionsReport()}
                  >{t('current_account_transactions_report')}</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>


      <ToolkitProvider
        keyField="id"
        data={currentAccountTransactions}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>

      <AddNewMoveFromMain
        id={id} accountId={accountId}
        setAccountId={setAccountId} setAddMove={setAddMove}
        addMove={addMove} idUpdate={idUpdate} setIdUpdated={setIdUpdated} />

    </div>
  )
}

export default ListCurrentAccountTransactionComponent
