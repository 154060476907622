import React, { useState, useEffect } from 'react'
import { Link, resolvePath } from "react-router-dom";
import BannerImage from "../assets/ghalbanNlogo.png";
import "../styles/Home.css";
import ExchangeRateService from '../services/ExchangeRateService';
import BranchAccountService from '../services/BranchAccountService';
import CurrentAccountService from '../services/CurrentAccountService';
import HelperUtil from '../helpers/HelperUtil';
import { t } from 'i18next';
import ListCurrentAccountComponent from '../components/ListCurrentAccountComponent';
import FileDownloaderHelperUtil from '../helpers/FileDownloaderHelperUtil';
import ReportingService from '../services/ReportingService';
import { BsCalendarDate } from "react-icons/bs"
import { HiOutlineDocumentDownload } from "react-icons/hi"
import cookies from 'js-cookie';
import { authService } from '../services/auth/AuthService';
import axios from 'axios';


function Home() {

  const [exchangeRates, setExchangeRates] = useState([])
  const [accountsStats, setAccountsStats] = useState([])
  const [branchBalances, setBranchBalances] = useState([])
  const currentLanguageCode = cookies.get('i18next') || 'en'
  let dateNow = new Date().toLocaleDateString(currentLanguageCode ? 'en-GB' : "ar");
  // ghalban_lng

  useEffect(() => {
    getActiveExchangeRates();
    ExchangeRateService.getActiveExchangeRates().then((response) => {
      setExchangeRates(response.data)
    }).catch(error => {
      console.log(error);
    })
    getAllAccountsStats();

    getBranchBalances();
  }, [setExchangeRates])

  const getActiveExchangeRates = () => {
    ExchangeRateService.getActiveExchangeRates().then((response) => {
      setExchangeRates(response.data)
    }).catch(error => {
      console.log(error);
    })
  }

  const getBranchBalances = () => {
    BranchAccountService.getBranchAccountsBalances(authService.currentUserValue.id).then((response) => {
      setBranchBalances(response.data)
    }).catch(error => {
      console.log(error);
    })
  }

  const getAllAccountsStats = () => {
    // const headers = {
    //   'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    // };

    // axios.get('https://ahmed-tayeh.com/ghalban-backend/api/v1/currentAccounts/stats', { headers })
    //   .then(response => {
    //     console.log(response)
    //     setAccountsStats(response.data)
    //     // handle response
    //   })
    //   .catch(error => {
    //     // handle error
    //   });

    CurrentAccountService.getAllCurrentAccountsStats().then((response) => {
      setAccountsStats(response.data)
    }).catch(error => {
      console.log(error);
    })

  }

  const printGeneralAccountsBalance = () => {
    ReportingService.getAccountsGeneralBalance().
      then((response) => {
        var reportName = "الميزان العام - شركة الغلبان";
        FileDownloaderHelperUtil.downloadDocument(response, reportName)
      }).catch(error => {
      })
  }

  const displayTotalBalanceText = (totalBalance) => {
    if (totalBalance > 0) {
      return (<span style={{ color: 'green' }} >
        {totalBalance + " " + t('balance_credit')}
      </span>
      )
    }
    if (totalBalance < 0) {
      return (<span style={{ color: 'red' }} >
        {totalBalance + " " + t('balance_debit')}
      </span>
      )
    }
    else return "";
  }

  {/*
        <div className="home" style={{ backgroundImage: `url(${BannerImage})`, backgroundSize: '60% ', opacity : 0.2 } }>
      <div className="headerContainer">
        <h1> We give hope to Palestinians </h1>
        <p> Stand by Palestine</p>
        -->
  
        <Link to="/menu">
          <button> Donate Now </button>
        </Link> 
  */}



  return (
    <div className="home">
      <div className="headerContainer">

        {/**Exchange Rates */}
        <div className="container exchangeRates">
          <div className='d-flex justify-content-around px-lg-3 px-0 py-4 align-items-center'>
            <h2 className="header_exchangeRates"> {t('exchange_rate_list')} </h2>
            <h3 className='date'><BsCalendarDate /> {dateNow}</h3>
            <p style={{ display: 'none' }}></p>{/* don't remove it */}
          </div>

          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th> {t('exchange_rate_from_currency')} </th>
                <th> {t('exchange_rate_to_currency')} </th>
                <th> {t('exchange_rate_value')} </th>
              </tr>
            </thead>
            <tbody>
              {
                exchangeRates.map(
                  exchangeRate =>
                    <tr key={exchangeRate.id}>
                      <td>{HelperUtil.getCurrencyNameLocale(exchangeRate.fromCurrency)}</td>
                      <td> {HelperUtil.getCurrencyNameLocale(exchangeRate.toCurrency)}</td>
                      <td> {exchangeRate.rate} </td>
                    </tr>
                )
              }
            </tbody>
          </table>
        </div>


        {
            authService.userHasAuthority('generalbalance.view') &&
        <div className="container">
          <h2 className="text-center pb-3"> {t('nav_current_accounts')} </h2>

         
            <button onClick={() => printGeneralAccountsBalance()} className='btn-download'>
              {t('current_account_general_balance_report')}
              <HiOutlineDocumentDownload />
            </button>
          

          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th> {t('currency')} </th>
                <th> {t('user_accounts_number_of_accounts')} </th>
                <th> {t('balance_credit')} </th>
                <th> {t('balance_debit')} </th>
                <th> {t('balance')} </th>
              </tr>
            </thead>
            <tbody>
              {
                accountsStats.map(
                  accountStat =>
                    <tr key={accountStat.currency}>
                      <td>{HelperUtil.getCurrencyNameLocale(accountStat.currency)}</td>
                      <td> {accountStat.numberOfAccounts}</td>
                      <td style={{ color: 'green' }}> {accountStat.credit} </td>
                      <td style={{ color: 'red' }}> {accountStat.debit} </td>
                      <td> {displayTotalBalanceText(accountStat.total)}</td>
                    </tr>
                )
              }
            </tbody>
          </table>
        </div>
        }


    <div className="container branchBalances">
          <div className='d-flex justify-content-around px-lg-3 px-0 py-4 align-items-center'>
            <h2 className="header_exchangeRates"> {t('branch_accounts_balance')} </h2>
            <h3 className='date'><BsCalendarDate /> {dateNow}</h3>
            <p style={{ display: 'none' }}></p>{/* don't remove it */}
          </div>

          <table className="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th> {t('branch_account_name')} </th>
                <th> {t('currency')} </th>
                <th>  {t('balance')} </th>
              </tr>
            </thead>
            <tbody>
              {
                branchBalances.map(
                  balance =>
                    <tr >
                      <td>{balance.branchAccountName}</td>
                      <td> {HelperUtil.getCurrencyNameLocale(balance.currency)}</td>
                      <td> {balance.transactionAmount} </td>
                    </tr>
                )
              }
            </tbody>
          </table>
        </div>

      </div>
    </div>
  );
}

export default Home;
