import axios from 'axios'
import Helper from './ServiceHelperUtil'
import { isTokenExpired, updateAccessToken } from "./auth/ApiClient"
const BRANCH_ACCOUNT_BASE_REST_API_URL = Helper.getBaseURL() + '/api/v1/branchAccount';

//Cross-Origin Request
//resonse_object.header("Access-Control-Allow-Origin", "*");
//resonse_object.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");

async function getAccessToken() {
  let access_token = localStorage.getItem('access_token');
  if (isTokenExpired(access_token)) {
    console.log("Token expired. Requesting new...")
    await updateAccessToken();
    console.log("new AccessToken received");
    access_token = localStorage.getItem('access_token');
  }
  return access_token;
}
class BranchAccountService {


  /**
   * Get all branch Accounts
   * @returns 
   */
  async getAllBranchAccounts() {
    const accessToken = await getAccessToken();
    return axios.get(BRANCH_ACCOUNT_BASE_REST_API_URL, 
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    )
  }


   /**
   * Get all branch Accounts Balances
   * @returns 
   */
   async getBranchAccountsBalances(userId) {
    const accessToken = await getAccessToken();
    return axios.get(BRANCH_ACCOUNT_BASE_REST_API_URL + '/stats/'+ userId, 
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    )
  }


  /**
   * Get Accessible branch accounts based on the logged in user
   * @param {} userId 
   * @returns 
   */
  async getAccessibleBranchAccounts(userId) {
    const accessToken = await getAccessToken();
    // console.log("🚀  ExchangeRateService.js:29  accessToken:", accessToken)

    return axios.get(BRANCH_ACCOUNT_BASE_REST_API_URL + '/acc/'+ userId,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    )
  }


  async getBranchAccount(id) {
    const accessToken = await getAccessToken();

    return axios.get(BRANCH_ACCOUNT_BASE_REST_API_URL + '/' + id, 
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'ccess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });
  }

  /**
   * Create branch Account
   * 
   * @param {*} branchAccount 
   * @returns 
   */
  async createBranchAccount(branchAccount) {
    const accessToken = await getAccessToken();

    return axios.post(BRANCH_ACCOUNT_BASE_REST_API_URL, branchAccount,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      }
    )
  }

  
/**
 * Update branch Account
 * 
 * @param {*} branchAccountId 
 * @param {*} branchAccount 
 * @returns 
 */
  async updateBranchAccount(branchAccountId, branchAccount) {
    const accessToken = await getAccessToken();

    return axios.put(BRANCH_ACCOUNT_BASE_REST_API_URL + '/' + branchAccountId, branchAccount,
      {

        headers: {
          'Access-Control-Allow-Origin': '*',
          'ccess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

  }

  /**
   * Delete branch account
   * @param {*} branchAccountId 
   * @returns 
   */
  async deleteBranchAccount(branchAccountId) {
    const accessToken = await getAccessToken();

    return axios.delete(BRANCH_ACCOUNT_BASE_REST_API_URL + '/' + branchAccountId, {

        headers: {
          'Access-Control-Allow-Origin': '*',
          'ccess-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          'Accept': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      });

  }


}

export default new BranchAccountService();