import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from 'i18next';

import { Link, useNavigate, useParams } from 'react-router-dom';
import UserService from '../../services/UserService'


const AddNewUser = ({ addUser, setAddUser, userId, setUserId }) => {

  const handleClose = () => {
    setUserId(null)
    setAddUser(false)
    setErrors("")
  };

  const [roleType, setRoleType] = useState('')
  const [logingUserName, setLogingUserName] = useState('')
  const [password, setPassword] = useState('')
  const [userName, setUserName] = useState('')
  const [canHaveCurrentAccount, setCanHaveCurrentAccount] = useState('')
  const [allowedCountries, setAllowedCountries] = useState([])
  const [country, setCountry] = useState('')
  const [address, setAddress] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [mainMobileNumber, setMainMobileNumber] = useState('')
  const [secondaryMobileNumber, setSecondaryMobileNumber] = useState('')

  // const navigate = useNavigate();
  const [errors, setErrors] = useState('')


  useEffect(() => {
    if (userId) {
      UserService.getUsersById(userId).then((response) => {
        setRoleType(response.data.roleType)
        setLogingUserName(response.data.logingUserName)
        setPassword(response.data.password)

        setUserName(response.data.userName)
        //country, address, emailAddress, mainMobileNumber,  secondaryMobileNumber,
        setCountry(response.data.country)
        setAddress(response.data.address)
        setEmailAddress(response.data.emailAddress)
        setMainMobileNumber(response.data.mainMobileNumber)
        setSecondaryMobileNumber(response.data.secondaryMobileNumber)

      }).catch(error => {
        console.log(error)
      })
    }
    else {
      setUserId(null)
      setRoleType("")
      setLogingUserName("")
      setPassword("")
      setUserName("")
      setCountry("")
      setAddress("")
      setEmailAddress("")
      setMainMobileNumber("")
      setSecondaryMobileNumber("")
      UserService.getAllowedCountries().then((response) => {
        setAllowedCountries(response.data)
        // setAddUser(false)
      }).catch(error => {
        console.log(error)
      })
    }
  }, [userId, UserService, addUser])


  const saveOrUpdateUser = (e) => {
    e.preventDefault();
    if (roleType === undefined || roleType === "" ||
      userName === undefined || userName === "" ||
      mainMobileNumber === undefined || mainMobileNumber === "" ||
      country === undefined || country === ""
    ) {
      setErrors("errors");
    }
    else {
      //  setAuth({ user, pwd, accessToken });
      const user = { roleType, logingUserName, password, userName, country, address, emailAddress, mainMobileNumber, secondaryMobileNumber }
      if (userId) {
        UserService.updateUser(userId, user).then((response) => {
          // navigate('/users')
          handleClose()
        }).catch(error => {
          console.log(error)
        })
      } else {
        UserService.createUser(user).then((response) => {
          console.log(response.data)
          // navigate('/users');
          handleClose()
        }).catch(error => {
          console.log(error)
        })
      }
    }  //else everything is okay
  }


  const title = () => {
    if (userId) {
      return <h2 className="text-center">{t('user_update')} </h2>
    } else {
      return <h2 className="text-center"> {t('user_add_new')}</h2>
    }
  }

  return (
    <>
      <Modal show={addUser} onHide={handleClose} size="lg" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
            <div className="container">
              <div className="row">
                <div className="card col-md-12 ">
                  <div className="card-body">
                    {errors !== "" ? (
                      <div id="errorMsg" className="alert alert-danger" role="alert"
                      >
                        Fill in the required information marked with *
                      </div>
                    ) : (<div> </div>)}
                    <form className="p-0">

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('user_role_type')} </label>
                        <select
                          name="roleType"
                          placeholder="Enter role type"
                          className="form-control"
                          value={roleType}
                          onChange={(e) => setRoleType(e.target.value)}>
                          <option selected="selected" value="ADMIN" > {t('user_admin_role_name')} </option>
                          <option value="ACCOUNTANT">{t('user_accountant_role_name')}</option>
                          <option value="BROKER"> {t('user_broker_role_name')} </option>
                          <option value="MEDIATOR"> {t('user_mediator_role_name')} </option>
                          <option value="CLIENT"> {t('user_client_role_name')} </option>
                        </select>

                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('user_name')} </label>
                        <input
                          type="text"
                          placeholder={t('guide_placeholder_enter_text') + ' ' + t('user_name')}
                          name="userName"
                          className="form-control"
                          required="required"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                        >
                        </input>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label">  {t('country_name')} </label>
                        <select
                          name="countryName"
                          placeholder={t('guide_placeholder_choose_text') + ' ' + t('country_name')}
                          // placeholder={'enter '}
                          className="form-control"
                          value={country}
                          // defaultValue={country}
                          onChange={(e) => setCountry(e.target.value)}>
                          {
                            allowedCountries.map(
                              allowedCountry =>
                                <option key={allowedCountry.countryName} value={allowedCountry.countryName}>{allowedCountry.countryName}</option>
                                )
                          }

                        </select>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('address')} </label>
                        <input
                          type="text"
                          placeholder={t('guide_placeholder_enter_text') + ' ' + t('address')}
                          name="address"
                          className="form-control"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        >
                        </input>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('user_email_address')} </label>
                        <input
                          type="text"
                          placeholder={t('guide_placeholder_enter_text') + ' ' + t('user_email_address')}
                          name="emailAddress"
                          className="form-control"
                          value={emailAddress}
                          required
                          onChange={(e) => setEmailAddress(e.target.value)}
                        >
                        </input>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('user_main_mobile')} </label>
                        <input
                          type="number"
                          placeholder={t('guide_placeholder_enter_text') + ' ' + t('user_main_mobile')}
                          name="mainMobileNumber"
                          className="form-control"
                          value={mainMobileNumber}
                          onChange={(e) => setMainMobileNumber(e.target.value)}
                        >
                        </input>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('user_sec_mobile')} </label>
                        <input
                          type="number"
                          placeholder={t('guide_placeholder_enter_text') + ' ' + t('user_sec_mobile')}
                          name="secondaryMobileNumber"
                          className="form-control"
                          value={secondaryMobileNumber}
                          onChange={(e) => setSecondaryMobileNumber(e.target.value)}
                        >
                        </input>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateUser(e)} >
            {t('crud_general_save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNewUser