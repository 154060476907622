import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Link, useNavigate, useParams } from 'react-router-dom';
import { t } from 'i18next';

import UserService from '../../services/UserService';
import UserTransferTransactionFeeService from '../../services/UserTransferTransactionFeeService';


const AddTransferFees = ({ showAddFeeClient, setShowAddFeeClient, idEdit, setIdEdit, }) => {

  let id = idEdit

  const [country, setCountry] = useState('')
  const [roleType, setRoleType] = useState('')
  const [fees, setFees] = useState('')
  const [feeType, setFeeType] = useState('')
  const [allowedCountries, setAllowedCountries] = useState([])

  const saveOrUpdateTransferTransactionFee = (e) => {
    e.preventDefault();

    const userTransferFee = { country, roleType, fees, feeType }
    // console.log(userTransferFee)
    if (id) {
      UserTransferTransactionFeeService.updateUserFee(id, userTransferFee).then((response) => {
        // navigate('/user-transfer-fees')
        setShowAddFeeClient(false)
      }).catch(error => {
        console.log(error)
      })
    } else {
      UserTransferTransactionFeeService.createUserTransferFee(userTransferFee).then((response) => {
        console.log(response.data)
        // navigate('/user-transfer-fees');
        setShowAddFeeClient(false)
      }).catch(error => {
        console.log(error)
      })
    }
  }

  useEffect(() => {
    if (id) {
      UserTransferTransactionFeeService.getUserTransferFeeById(id).then((response) => {
        setCountry(response.data.country)
        setFeeType(response.data.feeType)
        setFees(response.data.fees)
        setRoleType(response.data.roleType)
      }).catch(error => {
        console.log(error)
      })
    }
    else {
      setCountry(allowedCountries[0]?.countryName)
      setRoleType("BROKER")
      setFeeType("PERCENTAGE")
    }

    UserService.getAllowedCountries().then((response) => {
      setAllowedCountries(response.data)
    }).catch(error => {
      console.log(error)
    })

  }, [showAddFeeClient])

  const title = () => {

    if (id) {
      return <h2 className="text-center">{t('transfer_fee_update')}</h2>
    } else {
      return <h2 className="text-center">{t('user_transfer_fee_update')}</h2>
    }
  }

  const handleClose = () => {
    setShowAddFeeClient(false)
    setIdEdit(null)
    id = null
    setCountry("")
    setRoleType("")
    setFeeType("")
    setFees("")
  };

  return (
    <div>
      <Modal show={showAddFeeClient} onHide={handleClose} size="md" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            <div className="card col-md-12">
              <div className="card-body">
                <form className='p-0'>
                  <div className="form-group mb-2">
                    <label className="form-label">{t('user_transfer_fee_country_of_transaction')}</label>
                    <select
                      name="fromCountry"
                      placeholder="Choose From Country"
                      className="form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}>
                      {
                        allowedCountries.map(
                          (allowedCountry, index) =>
                            <option key={allowedCountry.countryName} value={allowedCountry.countryName}
                            >{allowedCountry.countryName}</option>
                        )
                      }
                    </select>
                  </div>
                  <div className="form-group mb-2">
                    <label className="form-label"> {t('user_role_type')} </label>
                    <select
                      name="roleType"
                      placeholder="Enter role type"
                      className="form-control"
                      value={roleType}
                      onChange={(e) => setRoleType(e.target.value)}>
                      <option value="BROKER"> {t('user_broker_role_name')} </option>
                      <option value="MEDIATOR"> {t('user_mediator_role_name')} </option>
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_type')}</label>
                    <select
                      name="toCountry"
                      placeholder="Choose To Country"
                      className="form-control"
                      value={feeType}
                      onChange={(e) => setFeeType(e.target.value)}>
                      <option value="PERCENTAGE">{t('transfer_fee_type_percentage')}</option>
                      <option value="VALUE">{t('transfer_fee_type_value')}</option>
                    </select>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label">{t('transfer_fee_value')}</label>
                    <input
                      type="text"
                      placeholder={t('transfer_fee_value')}
                      name="transferFee"
                      className="form-control"
                      value={fees}
                      onChange={(e) => setFees(e.target.value)}
                    >
                    </input>
                  </div>
                  {/* <button className="btn btn-success" onClick={(e) => saveOrUpdateTransferTransactionFee(e)} >Submit </button>
                  <Link to="/user-transfer-fees" className="btn btn-danger"> Cancel </Link> */}
                </form>
              </div>
            </div>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateTransferTransactionFee(e)} >
            {t('crud_general_save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default AddTransferFees