import React, { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import ExchangeRateService from '../../services/ExchangeRateService';
import TransferTransactionService from '../../services/TransferTransactionService';
import { useTranslation } from "react-i18next";


const HeaderHome = () => {
  const [fromCountry, setFromCountry] = useState('');
  const [toCountry, setToCountry] = useState('');

  const [fromCurrency, setFromCurrency] = useState('');
  const [toCurrency, setToCurrency] = useState('');

  const [sendAmount, setSendAmount] = useState('');
  const [receiveAmount, setReceiveAmount] = useState('');

  const [countries, setCountries] = useState()
  const [currencies, setCurrencies] = useState()

  const [allFees, setAllFees] = useState();

  const[exchangeRateAvailabe, setExchangeRateAvailabe] = useState('')

  const[countryFeesAvailable, setCountryFeesAvailable] = useState('')
  const [errorsExchangeRate, setErrorsExchangeRate] = useState('')
  const [errorsCountryFees, setErrorsCountryFees] = useState('')
  const { t } = useTranslation()

  useEffect(() => {
    UserService.getAllowedCountries2()
      .then((res) => {
        setCountries(res.data)
      })
      .catch(error => {
        console.log(error)
      })

    ExchangeRateService.getSupportedCurrencies()
      .then((res) => {
        setCurrencies(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const handleFromCountryChange = (event) => {
    setFromCountry(event.target.value);
  };

  const handleToCountryChange = (event) => {
    setToCountry(event.target.value);
  };

  const handleFromCurrencyChange = (event) => {
    setFromCurrency(event.target.value);
  };

  const handleToCurrencyChange = (event) => {
    setToCurrency(event.target.value);
  };

  const handleSendAmountChange = (event) => {
    setSendAmount(event.target.value);
  };

  const handleReceiveAmountChange = (event) => {
    setReceiveAmount(event.target.value);
  };

  const handleSubmit = (event) => {
    setErrorsExchangeRate("");
    setErrorsCountryFees("");
    event.preventDefault();
    // Perform currency conversion or any other necessary logic here

    const object =
    // {
    //   "fromCountry": "بلجيكا", "toCountry": "العراق",
    //   "fromCurrency": "TRY", "toCurrency": "USD",
    //   "sentMoney": "500"
    // }
    {
      "fromCurrency": fromCurrency, "toCurrency": toCurrency,
      "fromCountry": fromCountry, "toCountry": toCountry,
      "sentMoney": sendAmount
    }

    // allFees: 7.1التكلفة || =
    // netReceived: 980.871 

    TransferTransactionService.calcuateTransferTransaction(object)
      .then((res) => {
        if(res.data?.exchangeRateAvailabe !== false && res.data?.countryFeesAvailable !== false){
        setReceiveAmount(res.data?.netReceived)
        setAllFees(res.data?.allFees)
        
        }

        if(res.data?.exchangeRateAvailabe === false){
        
          setErrorsExchangeRate("Errors");
        }
        if(res.data?.countryFeesAvailable === false){
          setErrorsCountryFees("Errors");
        }
        console.log(res.data)
      })
      .catch(error => {
        console.log(error)
      })
  };


  return (
    <div className="container py-5">
      <div className='d-flex align-items-center flex-lg-row flex-column justify-content-between'>

        <div className='header_home_conversion'>
          <h2>International money transfer</h2>
          <p>Fast, flexible and secure international money transfers across the world.
            Save time and money when you send money internationally with us.</p>
        </div>

        <div className="currency-conversion-form">
          <form onSubmit={handleSubmit} className='form_convert_money'>
            <h3>{t('transfer_fee_international_transfer_title')}</h3>
            <div className="form-group">

              <label htmlFor="fromCountry">{t('transfer_fee_from_country_and_fees')}</label>
              <div className='d-flex justify-content-between'>
                <select id="fromCountry" className='' value={fromCountry} onChange={handleFromCountryChange}>
                  <option value="">{t('transfer_fee_from_country')}</option>
                  {
                    countries && countries.map((con) =>
                      <option value={con.countryName} key={con.id} className="mb-2 h6">{con.countryName}</option>
                    )
                  }
                </select>

                <select id="fromCurrency" className=' ' value={fromCurrency} onChange={handleFromCurrencyChange}>
                  <option value="">{t('transfer_fee_from_currency')}</option>
                  {
                    currencies && currencies.map((currence) =>
                      <option value={currence} key={currence} className="mb-2 h6">{currence}</option>
                    )
                  }
                </select>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="sendAmount">{t('transfer_fee_send_amount')}</label>
              <input type="number" id="sendAmount" value={sendAmount} onChange={handleSendAmountChange} />
            </div>

            <div className="form-group">
              <label htmlFor="toCountry">{t('transfer_fee_to_country_and_fees')}</label>
              <div className='d-flex justify-content-between'>
                <select id="toCountry" value={toCountry} className=' ' onChange={handleToCountryChange}>
                  <option value="">{t('transfer_fee_to_country')}</option>
                  {
                    countries && countries.map((con) =>
                      <option value={con.countryName} key={con.id} className="mb-2 h6">{con.countryName}</option>
                    )
                  }
                </select>

                <select id="toCurrency" className=' ' value={toCurrency} onChange={handleToCurrencyChange}>
                  <option value="">{t('transfer_fee_to_currency')}</option>
                  {
                    currencies && currencies.map((currence) =>
                      <option value={currence} key={currence} className="mb-2 h6">{currence}</option>
                    )
                  }
                </select>
              </div>

            </div>

            <div className="form-group mb-2">
              <label htmlFor="receiveAmount">{t('transfer_fee_receive_amount')}</label>
              <input type="number" id="receiveAmount" value={receiveAmount} disabled
                onChange={handleReceiveAmountChange} />
            </div>

            <div className="form-group mb-2">
              <label htmlFor="allFees">{t('transfer_fee_our_fees')}</label>
              <input type="number" id="allFees" value={allFees} disabled />
            </div>

            <button type="submit" className='convert_money'>{t('transfer_fee_simulate_transfer')}</button>
            <label>{t('transfer_fee_fees_note')} </label>
            {errorsCountryFees !== "" ? (
                <div id="errorMsg" className="alert alert-danger" role="alert"
                > {t('transfer_fee_country_fees_not_availbale')}
                </div>
              ) : (<div> </div>)}

              {errorsExchangeRate !== "" ? (
                <div id="errorMsg" className="alert alert-danger" role="alert"
                > {t('transfer_fee_exchange_rate_not_available')}
                </div>
              ) : (<div> </div>)}
          </form>
        </div>
      </div>

    </div >
  )
}

export default HeaderHome