import React from 'react';
import ListActiveExchangeRateComponent from '../components/ListActiveExchangeRateComponent';

const ExchangeRates = () => {
  return (
    <div>
    <ListActiveExchangeRateComponent />

    </div>
  )
}

export default ExchangeRates