import React from 'react';
//import ListActiveExchangeRateComponent from '../components/ListActiveExchangeRateComponent';
import ListTransferTransactionCountriesPublic from '../components/ListTransferTransactionCountriesPublic';
const CountryFees = () => {
  return (
    <div>
    <ListTransferTransactionCountriesPublic />

    </div>
  )
}

export default CountryFees