import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import CurrentAccountService from '../services/CurrentAccountService'
import ReportingService from '../services/ReportingService';
import ListCurrentAccountTransactionComponent from './ListCurrentAccountTransactionComponent';
import download from 'downloadjs';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import e from 'cors';
import Moment, { format } from 'moment';
import HelperUtil from '../helpers/HelperUtil';
import FileDownloaderHelperUtil from '../helpers/FileDownloaderHelperUtil';
import AddNewCurrentAccount from './modals/AddNewCurrentAccount';
import AddNewMove from './modals/AddNewMove';
import DeleteAccount from './modals/DeleteAccount';
import { authService } from '../services/auth/AuthService';

const ListCurrentAccountComponent = () => {

  const [currentAccounts, setCurrentAccounts] = useState([])
  const transaction = 'currentAccount';
  const { t } = useTranslation();
  
  // const { SearchBar } = Search;
  //  Search.searchProps.srText ("RRRRRRRRRRRRRRR")

  const [addCurrentA, setAddCurrentA] = useState(false)
  const [addMove, setAddMove] = useState(false)

  const [sureDelete, setSureDelete] = useState(false)

  const [userId, setUserId] = useState(null)

  const [accountId, setAccountId] = useState(null)

  useEffect(() => {
    // getAllCurrentAccounts();
    //   CurrentAccountService.getAllCurrentAccounts().then((response) => {
    CurrentAccountService.getAccessibleCurrentAccounts(authService.currentUserValue.id).then((response) => {
      setCurrentAccounts(response.data)
      // console.log("(response.data)", response.data)
    }).catch(error => {
      console.log(error);
    })
  }, [userId, addMove, addCurrentA])

  // const getAllCurrentAccounts = () => {
  //   CurrentAccountService.getAllCurrentAccounts().then((response) => {
  //     setCurrentAccounts(response.data)
  //   }).catch(error => {
  //     console.log(error);
  //   })
  // }

  const ModalDeleteAccount = (currentAccountId) => {
    setSureDelete(true)
    setUserId(currentAccountId)
  }

  // const deleteCurrentAccount = (currentAccountId) => {
  //   CurrentAccountService.deleteCurrentAccount(currentAccountId).then((response) => {
  //     getAllCurrentAccounts();
  //   }).catch(error => {
  //     console.log(error);
  //   })
  // }


  const showAddNewMove = (e) => {
    setAccountId(e)
    setUserId(null)
    setAddMove(true)
  }

  const createTransactionsLink = (row) => {
    return (
      <>
        {
          row["numberOfAccountTransactions"] > 0 ?
            <Link className="btn btn-primary mb-2 mx-xl-2 mx-0" to={`/currentAccountTransactions/${row["id"]}`} >
              {t('current_account_transactions')}
            </Link>
            :
            <button className="btn btn-primary mb-2 mx-xl-2 mx-0" disabled={true}>
              {t('current_account_transactions')}
            </button>
        }
        {authService.userHasAuthority('currentAccountTransaction.create') &&
          <button className="btn btn-primary mb-2" onClick={() => showAddNewMove(row["id"])}>
            {t('current_account_transaction_add_new')}
          </button>
        }
      </>
    )
  }

  const showUpdateCurrentA = (e) => {
    setUserId(e)
    setAccountId(e)
    setAddCurrentA(true)
  }
  const showAddNewCurrentA = (e) => {
    setUserId(null)
    setAddCurrentA(true)
  }

  const createCommonActions = (row) => {
    return (
      <div>
        {
          authService.userHasAuthority('currentAccount.update') &&
            // row["numberOfAccountTransactions"] <= 0 ? (
            <button className="btn btn-info" disabled={row["numberOfAccountTransactions"] <= 0 ? false : true}  onClick={() => showUpdateCurrentA(row["id"])} >{t('crud_general_update')}</button>
          // ) : (
          //   <button className="btn btn-info" disabled >{t('crud_general_update')}</button>)
        }

        {authService.userHasAuthority('currentAccount.delete') &&
          <button className="btn btn-danger mx-xl-2 mx-0" onClick={() => ModalDeleteAccount(row["id"])} disabled
          > {t('curd_general_delete')}</button>
        }

        <button className="btn btn-primary" onClick={() => printAccountTransactions(row["id"], row["userName"])}
          disabled={row["numberOfAccountTransactions"] <= 0}
        >{t('current_account_all_transactions_report')}</button>
      </div>
    )
  }

  const printGeneralAccountsBalance = () => {
    ReportingService.getAccountsGeneralBalance().
      then((response) => {
        var reportName = "الميزان العام - شركة الغلبان";
        FileDownloaderHelperUtil.downloadDocument(response, reportName);
      }).catch(error => {
      })
  }


  const printAccountTransactions = (currentAccountId, userName) => {
    //   ReportingService.getDummyReport().
    ReportingService.getAccountTransactionsReport(currentAccountId,
      "BEGIN", "END").
      then((response) => {
        var fileName = "حركات حساب" + "-" + userName;
        FileDownloaderHelperUtil.downloadDocument(response, fileName);
      }).catch(error => {
      })
  }
  //const idText = '{${t('current_account_id')};

  const columns = [
    {
      dataField: "accountIdentifier",
      text: t('current_account_identifier'),
      sort: true
    },
    {
      dataField: "userIdentifier",
      text: t('user_id'),
      sort: true
    },
    {
      dataField: "userName",
      text: t('user_name'),
      sort: true
    },
    {
      dataField: "accountCurrency",
      text: t('currency'),
      sort: true,
      formatter: (cell, row) => HelperUtil.getCurrencyNameLocale(row["accountCurrency"])
    },
    {
      dataField: "balance",
      text: t('balance'),
      sort: true
    },
    //     {
    //        dataField : "activationDate",
    //        text :  t('activation_date'),
    //        formatter : (cell, row) => HelperUtil.formatDate(row["activationDate"])
    //   },
    {
      dataField: "numberOfAccountTransactions",
      text: t('current_account_number_of_transactions')
    },
    {
      dataField: "activationDate",
      text: t('current_account_transactions_title'),
      formatter: (cell, row) => createTransactionsLink(row)
    },
    {
      dataField: "activationDate",
      text: t('crud_general_actions'),
      formatter: (cell, row) => createCommonActions(row)
    }
  ]



  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between'>
        <div>
          {authService.userHasAuthority('currentAccount.create') &&
            <button className="btn btn-primary" onClick={() => showAddNewCurrentA()}>{t('current_account_add_new')}</button>
          }

          {authService.userHasAuthority('generalbalance.view') &&
            <button className="btn btn-primary mx-xl-5 mx-0" onClick={() => printGeneralAccountsBalance()}>{t('current_account_general_balance_report')}</button>
          }

        </div>
        <input
          className="form-control search-input-moh"
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />
      </div>
    );
  };

  return (
    <div className="container py-5">
      <h2 className="text-center pt-2 pb-4"> {t('current_account_list')} </h2>

      <ToolkitProvider
        keyField="id"
        data={currentAccounts}
        columns={columns}
        search
        key={Math.ceil(Math.random())}
        bootstrap4
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                hover
                keyField='id'
              />
            </div>
          )
        }
      </ToolkitProvider>


      <AddNewCurrentAccount
        accountId={accountId} setAccountId={setAccountId}
        userId={userId} setUserId={setUserId}
        addCurrentA={addCurrentA} setAddCurrentA={setAddCurrentA}
      />

      <AddNewMove
        userId={userId} setUserId={setUserId}
        addMove={addMove} setAddMove={setAddMove}
        accountId={accountId} setAccountId={setAccountId}
      />

      <DeleteAccount
        sureDelete={sureDelete} setSureDelete={setSureDelete}
        setCurrentAccounts={setCurrentAccounts} userId={userId}
      />


    </div>
  )
}

export default ListCurrentAccountComponent
