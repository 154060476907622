import React, { useState, useEffect, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { t } from 'i18next';

import { Link, useNavigate, useParams } from 'react-router-dom';
import UserService from '../../services/UserService'
import CurrentAccountService from '../../services/CurrentAccountService'
import Moment, { format } from 'moment';
import SelectSearch, { fuzzySearch } from "react-select-search";
import '../../styles/select-search.css';

const AddNewCurrentAccount = ({ userId, setUserId, addCurrentA, setAddCurrentA, accountId, setAccountId }) => {

  const handleClose = () => {
    setUserId(null)
    setAccountId(null)
    setAddCurrentA(false)
    setErrors("")
  };

  const [clientId, setClientId] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [accountCurrency, setAccountCurrency] = useState('')
  const [balance, setBalance] = useState('')
  const [activationDate, setActivationDate] = useState('')
  const [clientAllowedForCurrentAccounts, setClientAllowedForCurrentAccounts] = useState([]);
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();

  const searchInput = useRef();
  const [usersList, setUsersList] = useState([]);
  //  const usersList = [];

  const options = [
    {
      type: "group",
      name: "Atlanta",
      items: [
        { name: "Workshop One", value: "1" },
        { name: "Workshop Two", value: "2" }
      ]
    },
    {
      type: "group",
      name: "Charleston",
      items: [
        { name: "Workshop Three", value: "3" },
        { name: "Workshop Four", value: "4" },
        { name: "Workshop Five", value: "5" }
      ]
    },
    {
      type: "group",
      name: "Inactive",
      items: [{ name: "Inactive Workshop", value: "100" }]
    }
  ];


  useEffect(() => {
    setBalance(0);
    // con date = new Date();
    setActivationDate(new Date());
    //yyyy-MM-dd'T'HH:mm:ss.SSSZ
    //  Moment().format(activationDate, 'MMMM Do YYYY, h:mm:ss a');

    if (accountId) {
      CurrentAccountService.getCurrentAccountById(userId)
        .then((response) => {
          setClientId(response.data.clientId)
          setFirstName(response.data.firstName)
          setLastName(response.lastName)
          setBalance(response.data.balance)
          setAccountCurrency(response.data.accountCurrency)
          setActivationDate(response.data.activationDate)
        }).catch(error => {
          console.log(error)
        })
    }
    //  else {
    UserService.getClientsAllowedForCurrentAccounts()
      .then((response) => {
        setClientAllowedForCurrentAccounts(response.data)
        const userL = [];
        // console.log(response.data.length);
        for (var i = 0; i < response.data.length; i++) {
          userL.push({ name: response.data[i].userName, value: response.data[i].id })
        }
        // console.log(userL);
        setUsersList(userL);
      })
    // }
  }, [userId, accountId, addCurrentA])

  const saveOrUpdateCurrentAccount = (e) => {
    e.preventDefault();
    if (clientId === undefined || clientId === "" ||
      accountCurrency === undefined || accountCurrency === ""
    ) {
      setErrors("errors");
    }
    else {
      const currentAccount = { clientId, firstName, lastName, accountCurrency, balance, activationDate }
      if (userId) {
        CurrentAccountService.updateCurrentAccount(accountId, currentAccount).then((response) => {
          setUserId(null)
          setAddCurrentA(false)
          // navigate('/currentAccounts')
        }).catch(error => {
          console.log(error)
        })
      }
      else {
        CurrentAccountService.createCurrentAccount(currentAccount).then((response) => {
          setAddCurrentA(false)
          // console.log(response.data)
          // navigate('/currentAccounts');
        }).catch(error => {
          alert(error);
        })
      }
    }//else everything is okay
  }

  const title = () => {
    if (accountId) {
      return <h2 className="text-center">{t('current_account_update')}</h2>
    } else {
      return <h2 className="text-center">{t('current_account_add_new')}</h2>
    }
  }

  const handleChange = (...args) => {
    // searchInput.current.querySelector("input").value = "";
    console.log("ARGS:", args);
    console.log("Id", args.value);
    setClientId(args[1].value);

    // console.log("CHANGE:");
  };

  const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return usersList;
      }
      const updatedItems = [];
      for (var i = 0; i < usersList.length; i++) {
        if (usersList[i].name.toLowerCase().includes(searchValue.toLowerCase()));
        updatedItems.push(usersList[i]);
      }

      return updatedItems;
    };
  };

  return (
    <>
      <Modal show={addCurrentA} onHide={handleClose} size="md" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div>
            <div className="container">
              <div className="row">
                <div className="card col-md-12 ">
                  <div className="card-body">
                    {errors !== "" ? (
                      <div id="errorMsg" className="alert alert-danger" role="alert"
                      >
                        Client and account currency cannot be empty
                      </div>
                    ) : (<div> </div>)}

                    <form className='p-0'>
                      <div className='' >
                        <label className="form-label"> {t('current_account_client')} </label>
                        <SelectSearch
                          ref={searchInput}
                          options={usersList}
                          filterOptions={fuzzySearch}
                          value={clientId}
                          name="Workshop"
                          placeholder={t('guide_placeholder_choose_text') + t('current_account_client')}
                          search
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('currency')} </label>
                        <select
                          name="currency"
                          placeholder="Enter Currency"
                          className="form-control"
                          value={accountCurrency}
                          onChange={(e) => setAccountCurrency(e.target.value)}>
                          <option>{t('guide_placeholder_choose_text')} {t('currency')}</option>
                          <option value="USD">{t('currency_usd')}</option>
                          <option value="EUR">{t('currency_eur')}</option>
                          <option value="JOD">{t('currency_jod')}</option>
                          <option value="ILS">{t('currency_ils')}</option>
                          <option value="TRY">{t('currency_try')}</option>
                          <option value="AED">{t('currency_aed')}</option>
                          <option value="EGP">{t('currency_egp')}</option>
                        </select>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('balance')} </label>
                        <input
                          type="text"
                          placeholder="Enter balance"
                          name="balance"
                          className="form-control"
                          value={balance}
                          disabled={true}
                        >
                        </input>
                      </div>

                      <div className="form-group mb-2">
                        <label className="form-label"> {t('activation_date')} </label>
                        <input
                          type="text"
                          placeholder="Enter Activation Date"
                          name="balance"
                          className="form-control"
                          value={Moment(activationDate).format('MMMM Do YYYY, h:mm:ss a')}
                          disabled
                        >
                        </input>
                      </div>
                      {/* <button className="btn btn-success" onClick={(e) => saveOrUpdateCurrentAccount(e)} >Submit </button> */}
                      {/* <Link to="/currentAccounts" className="btn btn-danger">Cancel</Link> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateCurrentAccount(e)} >
            {t('crud_general_save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddNewCurrentAccount