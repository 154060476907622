import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import ExchangeRateService from '../services/ExchangeRateService';
import Moment, { format } from 'moment';
import HelperUtil from '../helpers/HelperUtil';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';




const ListActiveExchangeRateComponent = () => {

  const [exchangeRates, setExchangeRates] = useState([])
  const { t } = useTranslation()

  const [showAddNewPrice, setShowAddNewPrice] = useState(false)
  const [idForEdit, setIdForEdit] = useState(null)

  useEffect(() => {
    getActiveExchangeRates();
  }, [showAddNewPrice])

  const getActiveExchangeRates = () => {
    ExchangeRateService.getActiveExchangeRates().then((response) => {
      setExchangeRates(response.data)
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }



  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
      
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />

      </div>
    );
  };

  const columns = [
    
    {
      dataField: "fromCurrency",
      text: t('exchange_rate_from_currency'),
      sort: true
    },
    {
      dataField: "toCurrency",
      text: t('exchange_rate_to_currency'),
      sort: true
    },
    {
      dataField: "rate",
      text: t('exchange_rate_value'),
      sort: true,
      //           formatter: (cell, row) => HelperUtil.getCurrencyNameLocale(row["accountCurrency"])
    }
  ]


  return (

    <div className="container py-5">
      <h2 className="text-center py-3"> {t('exchange_rate_list')} </h2>

      <ToolkitProvider
        keyField="id"
        data={exchangeRates}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>

   
    </div>
  )
}

export default ListActiveExchangeRateComponent
