import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom'
import UserService from '../services/UserService'
import useAuth from '../hooks/useAuth'
import ReportingService from '../services/ReportingService';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
// import ResponsiveTable from 'react-bootstrap-table2-responsive';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import AddNewUser from './modals/AddNewUser';
import { AiFillCaretDown } from "react-icons/ai"
import { authService } from '../services/auth/AuthService';

const ListUserComponent = () => {

  const [users, setUsers] = useState([])
  const { auth } = useAuth();
  const { t } = useTranslation()
  const [addUser, setAddUser] = useState(false)
  const [userId, setUserId] = useState(null)

  useEffect(() => {
    getAllUsers();
  }, [addUser])


  const getAllUsers = () => {
    //  UserService.getAllUsers().then((response) => {
    UserService.getAccessibleUsers(authService.currentUserValue.id).then((response) => {
      setUsers(response.data)
      console.log(response.data);
      console.log("logged auth");
      // console.log(auth);

    }).catch(error => {
      console.log(error);
    })
  }

  const deleteUser = (userId) => {
    UserService.deleteUser(userId).then((response) => {
      getAllUsers();
    }).catch(error => {
      console.log(error);
    })
  }

  const printCurrentAccountsBalanceValidationReport = (userId, userName) => {
    ReportingService.getClientCurrentAccountsBalanceValidationReport(userId).
      then((response) => {
        let binaryString = window.atob(response.data);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
          let ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        let blob = new Blob([bytes], { type: "application/pdf" });

        //   let blob = new Blob([response.data],   {type: 'application/pdf'});
        let url = window.URL.createObjectURL(blob);
        // Window.open(url);
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', ".file.pdf"); //or any other extension
        var fileName = "مطابقة حسابات" + "-" + userName;
        link.download = fileName + '.pdf';
        document.body.appendChild(link);
        link.click();
      }).catch(error => {

      })
  }

  const displayUserRole = (row) => {
    const roleType = row["roleType"];

    if (roleType === 'ADMIN') {
      return t('user_admin_role_name')
    }
    else if (roleType === 'MEDIATOR') {
      return t('user_mediator_role_name')

    }

    else if (roleType === 'ACCOUNTANT') {
      return t('user_accountant_role_name')

    }
    else if (roleType === "BROKER") {
      return t('user_broker_role_name')

    }
    else if (roleType === "CLIENT") {
      return t('user_client_role_name')

    }
  }

  const displayUserCompleteAddress = (row) => {
    return (row["country"] + ' ' + row["address"])
  }

  const showUpdateUser = (e) => {
    setUserId(e)
    setAddUser(true)
  }

  const showAddUser = (e) => {
    setUserId(null)
    setAddUser(true)
  }


  const createCommonActions = (row) => {
    return (
      <>
        <div>
          {
            authService.userHasAuthority('user.update') &&
            <button className="btn btn-info mb-2" onClick={() => showUpdateUser(row["id"])} >{t('crud_general_update')}</button>
          }
          {
            authService.userHasAuthority('user.delete') &&
            <button className="btn btn-danger mb-2 mx-1" disabled> {t('curd_general_delete')} </button>
            // <button className="btn btn-danger mx-1" onClick={() => deleteUser(row["id"])}> {t('curd_general_delete')} </button>
          }
          {
            <button className="btn btn-primary mx-" disabled={row["numberOfCurrentAccounts"] > 0 ? false : true} onClick={() => printCurrentAccountsBalanceValidationReport(row["id"], row["userName"])}>{t('user_accounts_report_validation')}</button>
          }
        </div>
      </>
    )
  }


  const columns = [
    {
      dataField: "userIdentifier",
      text: t('user_identifier'),
      sort: true,
      // icon: <AiFillCaretDown />

    },
    {
      dataField: "roleType",
      text: t("user_role_type"),
      sort: true,
      formatter: (cell, row) => displayUserRole(row)
    },
    {
      dataField: "userName",
      text: t("user_name"),
      sort: true
    },
    {
      dataField: "country",
      text: t("user_address"),
      sort: true,
      formatter: (cell, row) => displayUserCompleteAddress(row)
    },
    {
      dataField: "logingUserName",
      text: t("user_login_name"),
      sort: true
    },

    {
      dataField: "emailAddress",
      text: t("user_email_address"),
      sort: true
    },
    {
      dataField: "mainMobileNumber",
      text: t("user_main_mobile"),
      sort: true
    },
    {
      dataField: "user_sec_mobile",
      text: t("user_sec_mobile"),
      sort: true
    },

    {
      dataField: "numberOfCurrentAccounts",
      text: t('user_accounts_number_of_accounts')
    },

    {
      dataField: "user_sec_mobile",
      text: t("crud_general_actions"),
      sort: true,
      formatter: (cell, row) => createCommonActions(row)
    }
  ]

  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };

    return (
      <div className='d-flex justify-content-between'>

        <button className="btn btn-primary mb-2" onClick={() => showAddUser()} >
          {t('user_add_new')}</button>
        <input
          className="form-control search-input-moh"
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />
      </div>
    );
  };

  const options = {
    responsive: true
  }

  return (
    <div className='py-5'>
      <div className="container">
        <h2 className="text-center pt-1 pb-4"> {t('user_list_users')} </h2>

        <AddNewUser addUser={addUser} setAddUser={setAddUser} userId={userId} setUserId={setUserId} />

        <ToolkitProvider
          keyField="id"
          data={users}
          columns={columns}
          search
          key={Math.ceil(Math.random())}
          bootstrap4
        >
          {
            props => (
              <div>
                <CustomSearch {...props.searchProps} />
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory()}
                  hover
                  keyField='id'
                  options={options}
                />
                {/* <ResponsiveTable /> */}
                {/* </BootstrapTable> */}
              </div>
            )
          }
        </ToolkitProvider>

      </div>
    </div>
  )
}

export default ListUserComponent
