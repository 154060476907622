import axios from 'axios'
import { getAccessToken } from './auth/ApiClient';
import Helper from './ServiceHelperUtil'

const USER_TRANSFER_FEE_BASE_REST_API_URL = Helper.getBaseURL() + '/api/v1/user-transfer-fee';

class UserTransferTransactionFeeService {

    async getAllUserTransferFee() {
        const accessToken = await getAccessToken();
        return axios.get(USER_TRANSFER_FEE_BASE_REST_API_URL, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }

    async createUserTransferFee(userTransferFee) {
        const accessToken = await getAccessToken();
        return axios.post(USER_TRANSFER_FEE_BASE_REST_API_URL, userTransferFee, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }

    async getUserTransferFee(country, role) {
        const accessToken = await getAccessToken();
        return axios.get(USER_TRANSFER_FEE_BASE_REST_API_URL + '/' + country + '/' + role, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }

    async getUserTransferFeeById(id) {
        const accessToken = await getAccessToken();
        return axios.get(USER_TRANSFER_FEE_BASE_REST_API_URL + '/' + id, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }

    async updateUserFee(id, userTransferFee) {
        const accessToken = await getAccessToken();
        return axios.put(USER_TRANSFER_FEE_BASE_REST_API_URL + '/' + id, userTransferFee, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }

}

export default new UserTransferTransactionFeeService();