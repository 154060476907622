import axios from 'axios'
import { t } from 'i18next';
import Moment, {format} from 'moment';



class TransferFeeHelperUtil{

   

     downloadDocument = (response, fileName) => {
        let binaryString = window.atob(response.data);

        let binaryLen = binaryString.length;

        let bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }

        let blob = new Blob([bytes], {type: "application/pdf"});



        //   let blob = new Blob([response.data],   {type: 'application/pdf'});

        let url = window.URL.createObjectURL(blob);
        // Window.open(url);
        const link = document.createElement('a');
        link.href = url;
        // link.setAttribute('download', ".file.pdf"); //or any other extension
        link.download = fileName + '.pdf';
        document.body.appendChild(link);
        link.click();

       
       
    }

    getTransferFeeInWrittenPatternLan = (feeDelm, rangeValue, rangeValueMin, rangeValueMax) =>{
        let pattern = t('transfer_fee_transferred_value') + ' ';
        if(feeDelm === "BETWEEN"){
            pattern += t('transfer_fee_between') + ' ' + rangeValueMin + ", " + rangeValueMax;
        }
        else if (feeDelm === "EQUAL"){
            pattern += t('transfer_fee_equal') + ' ' + rangeValue;

        }
        else if (feeDelm === "GREATER"){
            pattern += t('transfer_fee_greater') + ' ' + rangeValue;

        }

        else if (feeDelm === "LESS"){
            pattern += t('transfer_fee_less') + ' ' + rangeValue;

        }
        return pattern;
    }

    
}

export default new TransferFeeHelperUtil();