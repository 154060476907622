import React, { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Link, useNavigate, useParams } from 'react-router-dom';
import ExchangeRateService from '../../services/ExchangeRateService';
import { t } from 'i18next';
import Moment, { format } from 'moment';

const AddNewPriceRate = ({ showAddNewPrice, setShowAddNewPrice, idForEdit, setIdForEdit }) => {


  const [fromCurrency, setFromCurrency] = useState('')
  const [toCurrency, setToCurrency] = useState('')
  const [date, setDate] = useState('')
  const [rate, setRate] = useState('')
  const navigate = useNavigate();
  // const { id } = useParams();


  const handleClose = () => {
    setShowAddNewPrice(false)
    setIdForEdit(null)
    setFromCurrency("")
    setToCurrency("")
    setDate("")
    setRate("")
  };

  const saveOrUpdateExchangeRate = (e) => {
    e.preventDefault();
    const exchangeRate = { fromCurrency, toCurrency, date, rate }
    if (idForEdit) {
      ExchangeRateService.updateExchangeRate(idForEdit, exchangeRate).then((response) => {
        // navigate('/exchange-rates')
        handleClose()
      }).catch(error => {
        console.log(error)
      })

    } else {
      ExchangeRateService.createExchangeRate(exchangeRate).then((response) => {
        // console.log(response.data)
        handleClose()
        // navigate('/exchange-rates');
      }).catch(error => {
        console.log(error)
      })
    }
  }

  useEffect(() => {
    setDate(new Date());

    if (idForEdit) {
      ExchangeRateService.getExchangeRate(idForEdit).then((response) => {
        setFromCurrency(response.data.fromCurrency)
        setToCurrency(response.data.toCurrency)
        setDate(response.data.date)
        setRate(response.data.rate)
      }).catch(error => {
        console.log(error)
      })
    }

  }, [showAddNewPrice, idForEdit])

  const title = () => {
    if (idForEdit) {
      return <h2 className="text-center">Update Exchange Rate</h2>
    } else {
      return <h2 className="text-center">Add Exchange Rate</h2>
    }
  }

  return (
    <>
      <Modal show={showAddNewPrice} onHide={handleClose} size="md" centered>
        <Modal.Header>
          <Modal.Title>{title()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="container">
            <div className="row">
              <div className="card col-md-12">
                <div className="card-body">
                  <form className='p-0'>
                    <div className="form-group mb-2">
                      <label className="form-label"> From Currency :</label>
                      <select
                        name="fromCurrency"
                        placeholder="Choose From Currency"
                        className="form-control"
                        value={fromCurrency}
                        onChange={(e) => setFromCurrency(e.target.value)}>
                        <option>{t('guide_placeholder_choose_text')} {t('currency')}</option>
                        <option value="USD">{t('currency_usd')}</option>
                        <option value="EUR">{t('currency_eur')}</option>
                        <option value="JOD">{t('currency_jod')}</option>
                        <option value="ILS">{t('currency_ils')}</option>
                        <option value="TRY">{t('currency_try')}</option>
                        <option value="AED">{t('currency_aed')}</option>
                        <option value="EGP">{t('currency_egp')}</option>
                      </select>

                    </div>

                    <div className="form-group mb-2">
                      <label className="form-label"> To Currency :</label>
                      <select
                        name="toCurrency"
                        placeholder="Choose to Currency"
                        className="form-control"
                        value={toCurrency}
                        onChange={(e) => setToCurrency(e.target.value)}>
                        <option>{t('guide_placeholder_choose_text')} {t('currency')}</option>
                        <option value="USD">{t('currency_usd')}</option>
                        <option value="EUR">{t('currency_eur')}</option>
                        <option value="JOD">{t('currency_jod')}</option>
                        <option value="ILS">{t('currency_ils')}</option>
                        <option value="TRY">{t('currency_try')}</option>
                        <option value="AED">{t('currency_aed')}</option>
                        <option value="EGP">{t('currency_egp')}</option>
                      </select>

                    </div>

                    <div className="form-group mb-2">
                      <label className="form-label"> Date :</label>
                      <input
                        type="text"
                        placeholder="Date"
                        name="dateRate"
                        className="form-control"
                        value={Moment(date).format('MMMM Do YYYY, h:mm:ss a')}
                        disabled
                      //   onChange = {(e) => setDate(e.target.value)}
                      >
                      </input>
                    </div>

                    <div className="form-group mb-2">
                      <label className="form-label"> Exchange Rate :</label>
                      <input
                        type="text"
                        placeholder="Enter Exchange Rate"
                        name="exchangeRate"
                        className="form-control"
                        value={rate}
                        onChange={(e) => setRate(e.target.value)}
                      >
                      </input>
                    </div>
                  </form>

                </div>
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t('crud_general_cancel')}
          </Button>
          <Button variant="primary" onClick={(e) => saveOrUpdateExchangeRate(e)} >
            {t('crud_general_save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </ >
  )
}

export default AddNewPriceRate