import { t } from 'i18next';
import React, {useState, useEffect, useRef} from 'react'
import {Link, useNavigate, useParams } from 'react-router-dom';
import TransferTransactionService from '../services/TransferTransactionService'
import UserService from '../services/UserService';
import SelectSearch, {fuzzySearch} from "react-select-search";

const AddTransferTransactionComponent = () => {
    

    const [brokerBusinessName, setBrokerBusinessName] = useState('')
    
    const [brokerId, setBrokerId] = useState('')
    const [sender, setSender] = useState('')
    const [senderName, setSenderName] = useState('')
    const [receiver, setReceiver] = useState('')
    const [receiverName, setReceiverName] = useState('')
    const [depositAmount, setDepositAmount] = useState('')
    const [depositCurrency, setDepositCurrency] = useState('')
    const [receivedCurrency, setReceivedCurrency] = useState('')
    const [receivedAmount, setReceivedAmount] = useState('')
    const [transactionIdentifier, setTransactionIdentifier] = useState('')
    const [requestedBy, setRequestedBy] = useState('')
    const [requestedByUserName, setRequestedByUserName] = useState('')
    const [executedBy, setExecutedBy] = useState('')
    const [executedByUserName, setExecutedByUserName] = useState('')
    const [transactionStatus, setTransactionStatus] = useState('')
    const [executionDate, setExecutionDate] = useState('')
    const [brokers, setBrokers] = useState([])
    const [allowedCountries, setAllowedCountries] = useState([])

    const [fromCountry, setFromCountry] = useState('')
    const [toCountry, setToCountty] = useState('')
    const navigate = useNavigate();
    const searchInput = useRef();
    const {id} = useParams();

    
    
    const calcualateTransactionDetails = (e) => {
        e.preventDefault();

        if(fromCountry && toCountry && depositCurrency && receivedCurrency && depositAmount){
            console.log(fromCountry)
            console.log(toCountry)
            console.log(depositCurrency)
            console.log(receivedCurrency)
            console.log("ready to calculate");
           const transactionDetails =  JSON.stringify({ 'fromCurrency':  depositCurrency, 'toCurrency' : receivedCurrency ,
           'fromCountry': fromCountry, 'toCountry': toCountry, 'sentMoney': depositAmount});
            TransferTransactionService.calcuateTransaction( JSON.parse(transactionDetails)
               )

            .then((response)=>{
           console.log(response.data)
           console.log("Receive coming from Server")
           console.log(response?.data?.receiveMoney)
           console.log("existing receive before update")
           console.log(receivedAmount)

           setReceivedAmount(400)
     //      receivedAmount = response.data.receiveMoney
   //  document.getElementsByName("receiveAmount").value( response.data.receiveMoney)
  
           console.log("receive after update")
           console.log(receivedAmount)
            }).catch(error =>{
                console.log(error)
            })
        }

    }

    const saveOrUpdateTransferTransaction = (e) => {
        e.preventDefault();

        const transferTransaction = {brokerBusinessName, brokerId, senderName , receiverName, depositAmount, depositCurrency, receivedCurrency, receivedAmount,
                                    transactionIdentifier, requestedByUserName, executedByUserName, transactionStatus, executionDate}
        

        if(id){
            TransferTransactionService.updateTransferTransaction(id, transferTransaction).then((response) => {
                navigate('/transferTransactions')
            }).catch(error => {
                console.log(error)
            })

        }else{
            transferTransaction.requestedByUserName= 'admin1';
            TransferTransactionService.createTransferTranscation(transferTransaction).then((response) =>{

                console.log(response.data)
    
                navigate('/transferTransactions');
    
            }).catch(error => {
                console.log(error)
            })
        }
        
    }

    useEffect(() => {
        UserService.getUsersByRoleType("BROKER").then((response) =>{

            const userL = [];
            console.log(response.data.length);
            for(var i=0; i<response.data.length; i++){
            userL.push({name: response.data[i].userName, value: response.data[i].id})
            }
            // console.log("New list");
            // console.log(userL);
            setBrokers(userL);

          /*
            console.log("response", response.data);
            setBrokers(response.data);
            console.log("response", response.data);
            console.log("Brokers " , brokers);
            */
        }).catch(error => {console.log(error)})
        
        TransferTransactionService.getTransferTransactionById(id).then((response) =>{
            //{brokerId, senderName , receiverName, depositAmount, depositCurrency, receiveCurrency, receiveAmount,
            //transactionIdentifier, requestedByUserName, executedByUserName, transactionStatus, executionDate}
           setBrokerBusinessName(response.data.broker.businessName)
           setBrokerId(response.data.brokerId)
           setSenderName(response.data.senderName)
           setReceiverName(response.data.receiverName)
           setDepositAmount(response.data.depositAmount)
           setDepositCurrency(response.data.depositCurrency)
           setReceivedCurrency(response.data.receivedCurrency)
           setReceivedAmount(response.data.receivedAmount)
           setTransactionIdentifier(response.data.transactionIdentifier)
           setRequestedByUserName(response.data.requestedByUserName)
           setExecutedByUserName(response.data.executedByUserName)
           setTransactionStatus(response.data.transactionStatus)
           setExecutionDate(response.data.executionDate)

           
        }).catch(error => {
            console.log(error)
        })

        UserService.getAllowedCountries().then((response) => {
            setAllowedCountries(response.data) 
         }).catch(error => {
             console.log(error)
         })
    }, [])

    const title = () => {

        if(id){
            return <h2 className = "text-center">Update Transfer Transaction</h2>
        }else{
            return <h2 className = "text-center">Add Transfer Transaction</h2>
        }
    }

    const handleChange = (...args) => {
        // searchInput.current.querySelector("input").value = "";
        console.log("ARGS:", args);
        console.log("Id", args.value);
        setBrokerId(args[1].value)
       // setClientId(args[1].value);
        console.log("change")
        console.log(brokerId);
      };

    

    return (
        <div>
           <br /><br />
           <div className = "container">
                <div className = "row">
                    <div className = "card col-md-6 offset-md-3 offset-md-3">
                       {
                           title()
                       }
                        <div className = "card-body">
                            <form>
                               

                            <div className = "form-group mb-2">
                                    <label className = "form-label">  From Country:</label>
                                    <select 
                                        name="countryName" 
                                        placeholder = "Choose Country Name"
                                        className = "form-control"
                                        value = {fromCountry}
                                        onChange = {(e) => setFromCountry(e.target.value)}>
                                          { 
                                          allowedCountries.map(
                                              allowedCountry =>
                                              <option key={allowedCountry.countryName} value= {allowedCountry.countryName}>{allowedCountry.countryName}</option>                                          )

                                            }
                                      
                                    </select>
                                </div>


                            <div className = "form-group mb-2">
                               
                                    <label className = "form-label"> Broker Business Name :</label>
                                    <SelectSearch
                                                    ref={searchInput}
                                                    options={brokers}
                                                    filterOptions={fuzzySearch}
                                                  
                                                    value={brokerId}
                                                    name="Workshop"
                                                    placeholder={t('guide_placeholder_choose_text') + t('current_account_client')}
                                                    search
                                                    onChange={handleChange}
                                                />
                                  {
                                    /*
                                    <select 
                                        name="brokerName" 
                                        placeholder = "Choose Broker Name"
                                        className = "form-control"
                                        value = {brokerId}
                                        onChange = {(e) => setBrokerId(e.target.value)}>
                                          { 
                                          
                              
                                          brokers.map(
                                              broker =>
                                              <option key={broker.id} value= {broker.id}>{broker.businessName}</option>                                          )

                                            }
                                      
                                    </select>
                                    */
                                                        }               
                                                                    
                                   
                                </div>

                                <div className = "form-group mb-2">
                                    <label className = "form-label"> Sender Name :</label>
                                    <input
                                        type = "text"
                                        placeholder = "Enter Sender Name"
                                        name = "senderName"
                                        className = "form-control"
                                        value = {senderName}
                                        onChange = {(e) => setSenderName(e.target.value)}
                                    >
                                    </input>
                                </div>

                                <div className = "form-group mb-2">
                                    <label className = "form-label">  To Country:</label>
                                    <select 
                                        name="countryName" 
                                        placeholder = "Choose Country Name"
                                        className = "form-control"
                                        value = {toCountry}
                                        onChange = {(e) => setToCountty(e.target.value)}>
                                          { 
                                          allowedCountries.map(
                                              allowedCountry =>
                                              <option key={allowedCountry.countryName} value= {allowedCountry.countryName}>{allowedCountry.countryName}</option>                                          )

                                            }
                                      
                                    </select>
                                </div>
                                <div className = "form-group mb-2">
                                    <label className = "form-label"> Receiver Name :</label>
                                    <input
                                        type = "text"
                                        placeholder = "Enter Receiver Name"
                                        name = "receiverName"
                                        className = "form-control"
                                        value = {receiverName}
                                        onChange = {(e) => setReceiverName(e.target.value)}
                                    >
                                    </input>
                                </div>

                               

                                <div className = "form-group mb-2">
                                    <label className = "form-label"> Deposit Currency :</label>
                                    <select 
                                        name="depositCurrency" 
                                        placeholder = "Enter Deposit Currency"
                                        className = "form-control"
                                        value = {depositCurrency}
                                        onChange = {(e) => setDepositCurrency(e.target.value)}>
                                        <option>{t('guide_placeholder_choose_text')} {t('currency')}</option>
                                       <option value="USD">{t('currency_usd')}</option>
                                        <option value="EUR">{t('currency_eur')}</option>
                                        <option value="JOD">{t('currency_jod')}</option>
                                        <option value="ILS">{t('currency_ils')}</option>
                                        <option value="TRY">{t('currency_try')}</option>
                                        <option value="AED">{t('currency_aed')}</option>
                                        <option value="EGP">{t('currency_egp')}</option>
                                    </select>
                                    
                                </div>

                                <div className = "form-group mb-2">
                                    <label className = "form-label"> Deposit Amount :</label>
                                    <input
                                        type = "text"
                                        placeholder = "Enter deposit amount"
                                        name = "depositAmount"
                                        className = "form-control"
                                        value = {depositAmount}
                                        onChange = {(e) => setDepositAmount(e.target.value)}
                                    >
                                    </input>
                                </div>

                                <div className = "form-group mb-2">
                                    <label className = "form-label"> Receive Currency :</label>
                                    <select 
                                        name="receiveCurrency" 
                                        placeholder = "Enter Receive Currency"
                                        className = "form-control"
                                        value = {receivedCurrency}
                                        onChange = {(e) => setReceivedCurrency(e.target.value)}>
                                         <option>{t('guide_placeholder_choose_text')} {t('currency')}</option>
                                       <option value="USD">{t('currency_usd')}</option>
                                        <option value="EUR">{t('currency_eur')}</option>
                                        <option value="JOD">{t('currency_jod')}</option>
                                        <option value="ILS">{t('currency_ils')}</option>
                                        <option value="TRY">{t('currency_try')}</option>
                                        <option value="AED">{t('currency_aed')}</option>
                                        <option value="EGP">{t('currency_egp')}</option>
                                    </select>
                                    
                                </div>

                                <div className = "form-group mb-2">
                                    <label className = "form-label"> Receive Amount :</label>
                                    <input
                                       
                                        type = "text"
                                        placeholder = "Should Receive"
                                        name = "receiveAmount"
                                        className = "form-control"
                                        value = {receivedAmount}
                                        disabled
                                        onChange = {(e) => setReceivedAmount(e.target.value)}
                                    >
                                    </input>
                                </div>

                              
                                <button className = "btn btn-success" onClick = {(e) => calcualateTransactionDetails(e)} >Calculate Transaction </button>
                                <button className = "btn btn-success" onClick = {(e) => saveOrUpdateTransferTransaction(e)} >Submit </button>
                                <Link to="/transferTransactions" className="btn btn-danger"> Cancel </Link>
                            </form>

                        </div>
                    </div>
                </div>

           </div>

        </div>
    )
}

export default  AddTransferTransactionComponent
