import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import HelperUtil from '../helpers/HelperUtil'
//import TransferTransactionFeeService from '../services/TransferTransactionFeeService'
import UserTransferTransactionFeeService from '../services/UserTransferTransactionFeeService'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AddTransferFees from './modals/AddTransferFees'

const ListUserTransferTransactionFeeComponent = () => {

  const [userTransferFees, setUserTransferFees] = useState([])
  const [showAddFeeClient, setShowAddFeeClient] = useState(false)
  const [idEdit, setIdEdit] = useState(null)

  useEffect(() => {
    getAllUserTransferFees();
  }, [showAddFeeClient, idEdit])

  const getAllUserTransferFees = () => {
    UserTransferTransactionFeeService.getAllUserTransferFee().then((response) => {
      setUserTransferFees(response.data)
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  const deleteTransferFee = (transferFeeId) => {
    /*
//      CurrentAccountService.deleteCurrentAccount(currentAccountId).then((response) =>{
TransferTransactionFeeService.deleteTransferTransactionFee(transferFeeId).then((response) => {
    getAllTransferFees();

   }).catch(error =>{
       console.log(error);
   })
    */
  }


  const showUpdateFeeForClient = (id) => {
    setIdEdit(id)
    setShowAddFeeClient(true)
  }

  const createCommonActions = (row) => {
    return (
      <>
        {/* <Link className="btn btn-info" to={`/edit-user-transfer-fee/${row["id"]}`} > {t('crud_general_update')} </Link> */}
        <button className="btn btn-info" onClick={() => showUpdateFeeForClient(row["id"])}> {t('crud_general_update')} </button>

        <button className="btn btn-danger" onClick={() => deleteTransferFee(row["id)"])}
          style={{ marginLeft: "10px" }}> {t('curd_general_delete')} </button>
      </>
    )
  }


  const showAddFeeForClient = (id) => {
    setIdEdit(null)
    setShowAddFeeClient(true)
  }

  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
        {/* <Link to="/add-user-transfer-fee" className="btn btn-primary mb-2" >{t('user_transfer_fee_add_new')} </Link> */}
        <button className="btn btn-primary mb-2" onClick={() => showAddFeeForClient()} >{t('user_transfer_fee_add_new')} </button>
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />

      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: t('user_transfer_fee_id'),
      sort: true
    },
    {
      dataField: "country",
      text: t('user_transfer_fee_country_of_transaction'),
      sort: true
    },
    {
      dataField: "roleType",
      text: t('user_role_type'),
      sort: true,
      formatter: (cell, row) => HelperUtil.getUserRoleTypeNameLang(row["roleType"])
    },
    {
      dataField: "feeType",
      text: t('transfer_fee_type'),
      sort: true,
      formatter: (cell, row) => HelperUtil.getFeeTypeNameLang(row["feeType"])
    },
    {
      dataField: "fees",
      text: t('transfer_fee_value'),
    },
    {
      dataField: "id",
      text: t('crud_general_actions'),
      formatter: (cell, row) => createCommonActions(row)
    }
  ]



  return (
    <div className="container py-5">
      <h2 className="text-center py-3"> {t('transfer_fee_list')} </h2>

      <ToolkitProvider
        keyField="id"
        data={userTransferFees}
        columns={columns}
        search
      >
        {
          props => (
            <>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </>
          )
        }
      </ToolkitProvider>

      <AddTransferFees
        showAddFeeClient={showAddFeeClient} setShowAddFeeClient={setShowAddFeeClient}
        idEdit={idEdit} setIdEdit={setIdEdit}
      />
    </div>
  )
}

export default ListUserTransferTransactionFeeComponent
