import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import BranchAccountService from '../services/BranchAccountService';
import Moment, { format } from 'moment';
import HelperUtil from '../helpers/HelperUtil';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import AddBranchAccount from './modals/AddBranchAccount';
import { authService } from '../services/auth/AuthService';



const ListBranchAccountComponent = () => {

  const [branchAccounts, setBranchAccounts] = useState([])
  const { t } = useTranslation()

  const [showAddBranchAccount, setShowAddBranchAccount] = useState(false)
  const [idForEdit, setIdForEdit] = useState(null)

  useEffect(() => {
    getAllBranchAccounts();
  }, [showAddBranchAccount])

  const getAllBranchAccounts = () => {
    BranchAccountService.getAllBranchAccounts().then((response) => {
      setBranchAccounts(response.data)
      // console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }

  const editBranchAccount = (idEdit) => {
    setIdForEdit(idEdit)
    setShowAddBranchAccount(true)
  }

  const createCommonActions = (row) => {
    return (
      <div>
        {/* <Link className="btn btn-info" to={`/edit-exchange-rate/${row["id"]}`} style={{ marginLeft: "10px", color: "#fff" }}> {t('crud_general_update')} </Link> */}
        {authService.userHasAuthority('branchAccount.update') &&
          <button className="btn btn-info mx-1" onClick={() => editBranchAccount(row["id"])}> {t('crud_general_update')} </button>
        }
        {/* <button className = "btn btn-danger" onClick = {() => deleteExchangeRate(row["id"])}
        style = {{marginLeft:"10px"}}> {t('curd_general_delete')}</button>
        */}
        {authService.userHasAuthority('branchAccount.delete') &&
          <button className="btn btn-danger mx-1" disabled style={{ marginLeft: "10px" }}> {t('curd_general_delete')}</button>
        }
      </div>
    )
  }


  /*
    const deleteExchangeRate = (exchangeRateId) => {
      ExchangeRateService.deleteExchangeRate(exchangeRateId).then((response) => {
        //   ExchangeRateService.getAllExchangeRates.then((response) =>{
        getAllExchangeRates();
      }).catch(error => {
        console.log(error);
      })
    }
    */

  const showModalNewBranchAccount = () => {
    setIdForEdit(null)
    setShowAddBranchAccount(true)
  }

  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
        {/* <Link to="/add-exchange-rate" className="btn btn-primary mb-2" > {t('exchange_rate_add_new')} </Link> */}
        {authService.userHasAuthority('branchAccount.create') &&
          <button className="btn btn-primary mb-2" onClick={() => showModalNewBranchAccount()}> {t('branch_account_add_new')} </button>
        }
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />

      </div>
    );
  };

  const columns = [
    {
      dataField: "id",
      text: t('branch_account_id'),
      sort: true
    },
    {
      dataField: "branchAccountName",
      text: t('branch_account_name'),
      sort: true
    },
    {
      dataField: "branchType",
      text: t('branch_account_name'),
      sort: true
    },
    {
      dataField: "relatedToExtraInfo",
      text: "Branch Details",
      sort: true,
      //  formatter: (row) => visualiseExtraInfoAboutBranchAccount(row[relatedToExtraInfo], row[relatedToId])
    },
    {
      dataField: "id",
      text: t('crud_general_actions'),
      formatter: (cell, row) => createCommonActions(row)
    }

  ]


  return (

    <div className="container py-5">
      <h2 className="text-center py-3"> {t('current_account_list')} </h2>

      <ToolkitProvider
        keyField="id"
        data={branchAccounts}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>


      <AddBranchAccount
        showAddBranchAccount={showAddBranchAccount} setShowAddBranchAccount={setShowAddBranchAccount}
        idForEdit={idForEdit} setIdForEdit={setIdForEdit}
      />


    </div>
  )
}

export default ListBranchAccountComponent
