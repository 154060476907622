import axios from 'axios'
import { getAccessToken } from './auth/ApiClient';
import Helper from './ServiceHelperUtil'

const TRANSFER_FEE_BASE_REST_API_URL = Helper.getBaseURL() + '/api/v1/transfer-fee';

class TransferTransactionFeeService {

    async getAllTransferFees() {
     //   const accessToken = await getAccessToken();

        return axios.get(TRANSFER_FEE_BASE_REST_API_URL, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                'Accept': 'application/json'
       //         'Authorization': `Bearer ${accessToken}`
            }
        })
    }

    async createTransferTransactionFee(transferTransactionFee) {
        const accessToken = await getAccessToken();

        return axios.post(TRANSFER_FEE_BASE_REST_API_URL, transferTransactionFee, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
    }

    async getTransferTransactionFee(fromCountry, toCountry) {
        const accessToken = await getAccessToken();

        return axios.get(TRANSFER_FEE_BASE_REST_API_URL + '/fee/' + fromCountry + '/' + toCountry, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }

    async getTransferTransactionFee(id) {
        const accessToken = await getAccessToken();

        return axios.get(TRANSFER_FEE_BASE_REST_API_URL + '/' + id, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }


    async updateTransferTransactionFee(transactionFeeId, transactionFee) {
        const accessToken = await getAccessToken();

        return axios.put(TRANSFER_FEE_BASE_REST_API_URL + '/' + transactionFeeId, transactionFee, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });

    }

    async deleteTransferTransactionFee(transactionFeeId) {
        const accessToken = await getAccessToken();

        return axios.delete(TRANSFER_FEE_BASE_REST_API_URL + '/' + transactionFeeId, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        });
    }



}

export default new TransferTransactionFeeService();