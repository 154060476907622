import { t } from 'i18next'
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import TransferTransactionFeeService from '../services/TransferTransactionFeeService';
import TransferFeeHelperUtil from '../helpers/TransferFeeHelperUtil';
import HelperUtil from '../helpers/HelperUtil';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';


const ListTransferTransactionCountriesPublic = () => {

  const [transferFees, setTransferFees] = useState([])
  const [showAddPriceModal, setShowAddPriceModal] = useState(false)
  const [id, setId] = useState(null)

  useEffect(() => {
    getAllTransferFees();
  }, [showAddPriceModal])

  const getAllTransferFees = () => {
    TransferTransactionFeeService.getAllTransferFees().then((response) => {
      setTransferFees(response.data)
      console.log(response.data);
    }).catch(error => {
      console.log(error);
    })
  }



  const CustomSearch = (props) => {
    let input;
    const handleSearchChange = () => {
      props.onSearch(input.value);
    };
    return (
      <div className='d-flex justify-content-between align-items-center pt-3'>
     
        <input
          className="form-control search-input-moh"
          style={{ backgroundColor: 'white' }}
          placeholder="Search Data"
          ref={n => input = n}
          type="text"
          onChange={handleSearchChange}
        />
      </div>
    );
  };

  const columns = [
   
    {
      dataField: "fromCountry",
      text: t('transfer_fee_from_country'),
      sort: true
    },
    {
      dataField: "toCountry",
      text: t('transfer_fee_to_country'),
      sort: true
    },
    {
      dataField: "feeDelm",
      text: t('transfer_fee_condition'),
      sort: true,
      formatter: (cell, row) => TransferFeeHelperUtil.getTransferFeeInWrittenPatternLan(row["feeDelm"], row["rangeValue"],
        row["rangeValueMin"], row["rangeValueMax"])
    },
    {
      dataField: "feeType",
      text: t('transfer_fee_type'),
      sort: true,
      formatter: (cell, row) => HelperUtil.getFeeTypeNameLang(row["feeType"])
    },
    {
      dataField: "fee",
      text: t('transfer_fee_value'),
      //  formatter : (cell, row) => HelperUtil.getFeeTypeNameLang(row["feeType"]) 
    }
  ]




  return (
    <div className="container py-5">
      <h2 className="text-center py-3"> {t('transfer_fee_international_transfer_fees_title')} </h2>
      <ToolkitProvider
        keyField="id"
        data={transferFees}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <h3></h3>
              <CustomSearch {...props.searchProps} />
              <hr />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
              />
            </div>
          )
        }
      </ToolkitProvider>

    </div>
  )
}

export default ListTransferTransactionCountriesPublic
