import React, { useEffect, useState } from "react";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import "../styles/Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authService } from "../services/auth/AuthService";

function Footer() {
  const { t } = useTranslation()

  const [userLogged, setUserLogged] = useState()


  useEffect(() => {
    authService.currentUser.subscribe(x => setUserLogged(x));
  }, []);

  return (
    <div className="py-5" style={{ background: "#fff" }}>

      <div className="container">

        <div className="footer">
          {userLogged ?
            <ul className="list-links">
              <Link to="/"> {t('nav_home')} </Link>
              <Link to="/users"> {t('nav_users')} </Link>
              <Link to="/currentAccounts"> {t('nav_current_accounts')} </Link>
              <Link to="/transferTransactions"> {t('nav_transfer_transactions')} </Link>

              <Link to="/exchange-rates">
                {t('nav_exchange_rates')}
              </Link>

              <Link to="/transfer-fees"> {t('nav_transfer_fees')} </Link>
              <Link to="/user-transfer-fees">{t('nav_user_transfer_fees')}</Link>
              <Link to="/">{t('nav_balance')}</Link>
            </ul>
            :
            <ul className="list-links">
              <Link to="/homeUser"> {t('nav_homeUser')} </Link>
              <Link to="/aboutUs">{t('nav_aboutUs')}</Link>
              <Link to="/sendMoney">{t('nav_sendMoney')}</Link>
              <Link to="/services">{t('nav_services')}</Link>
              <Link to="/exchangeRates">{t('nav_exchangeRates')}</Link>
              <Link to="/contact">{t('nav_contact')}</Link>
            </ul>
          }

          <div className="socialMedia">
            <InstagramIcon className="instagram-logo" /> <FacebookIcon className="facebook-logo" /> <TwitterIcon className="twitter-logo" />  <LinkedInIcon className="linkedin-logo" />
          </div>
        </div>
        <p className="copyRight"> &copy; 2022 Ghalban. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
